import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppBarContactComponent,
  AppPageComponent,
  AppBarComponent,
  AppFooterComponent,
} from "common";
import { ResetPasswordFormComponent } from "./reset-password-form/reset-password-form.component";
import { ResetPasswordDoneComponent } from "./reset-password-done/reset-password-done.component";
import { ResetPasswordGuard } from "../../infrastructure/guards/reset-password.guard";

const routes: Routes = [
  {
    path: "password/reset",
    component: AppPageComponent,
    title: "Reset Password – Idea Financial Partner",
    canActivate: [ResetPasswordGuard],
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: "simple",
      },
      appBar: {
        logoSize: "logo-s",
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        component: ResetPasswordFormComponent,
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
  {
    path: 'reset-done',
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: 'simple'
      },
      appBar: {
        logoSize: 'logo-s',
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: ResetPasswordDoneComponent
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResetPasswordRoutingModule { }
