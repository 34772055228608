import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarTitleService, parseNumber } from 'common';
import { BrokerStatus, BrokerStatusLabel } from 'projects/partner/src/app/shared/SharedConstants';
import { SubmissionDetailsFacade } from './submission-details.facade';
import { SubmissionDetailsHandleSharedActionsService } from './actions/submission-details-handle-shared-actions.service';
import { SubmissionDetailsActionsService } from './actions/submission-details-actions.service';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SubmissionDetailsParams } from './submission-details.model';

@UntilDestroy()
@Component({
  selector: 'ifbp-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.scss'],
  providers: [
    SubmissionDetailsFacade,
    SubmissionDetailsActionsService,
    SubmissionDetailsHandleSharedActionsService
  ]
})
export class SubmissionDetailsComponent implements OnInit {
  params: SubmissionDetailsParams;
  brokerStatus: BrokerStatus;
  isDataLoaded: boolean;
  readonly BrokerStatus = BrokerStatus;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly facade: SubmissionDetailsFacade,
    private readonly appBarTitleService: AppBarTitleService,
    private readonly actions: SubmissionDetailsActionsService,
    private readonly handleSharedActions: SubmissionDetailsHandleSharedActionsService,
  ) { }

  ngOnInit(): void {
    this.initData();
    this.initActions();
  }

  private initData() {
    const routerEvent$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd), startWith(this.router));
    const paramMap$ = this.route.paramMap;
    combineLatest([routerEvent$, paramMap$]).pipe(
      untilDestroyed(this),
      switchMap(([_, paramMap]: [any, ParamMap]) => {
        this.params = { appId: parseNumber(paramMap.get("id")) };
        return this.facade.getComponentBaseData$(this.params.appId)
          .pipe(untilDestroyed(this))
      })).subscribe(data => {
        this.brokerStatus = data.application.brokerStatus;
        this.appBarTitleService.title = `${data.account?.name ?? 'N/A'} - ${BrokerStatusLabel.get(this.brokerStatus)}`;
      });
  }

  private initActions() {
    this.handleSharedActions.watchActions();
    this.actions.markDataAsLoaded$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isDataLoaded = true;
    })
  }
}
