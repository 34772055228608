import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandingService, CompanyBranding, DialogActionComponent, DialogActionData, DomHelper, MessageService } from 'common';
import { SubmissionsFacade } from '../../../../../domain/+state/submissions.facade';
import { bankLinkSentDialogData, OfferShareActionsAdapter } from './offer-share-actions.data';
import { GenerateEmailOfferComponent } from './components/email-offer-details/generate-email-offer.component';
import { SendBankLinkComponent } from './components/send-bank-link-email/send-bank-link.component';
import { OfferShareActionsData, OfferShareActionsInputData } from './offer-share-actions.model';

@UntilDestroy()
@Component({
  selector: 'ifbp-offer-share-actions',
  templateUrl: './offer-share-actions.component.html',
  providers: [OfferShareActionsAdapter]
})
export class OfferShareActionsComponent implements OnChanges {
  data: OfferShareActionsData;

  @Input()
  inputData: OfferShareActionsInputData;

  constructor(
    private readonly submissionFacade: SubmissionsFacade,
    private readonly brandingService: BrandingService,
    private readonly adapter: OfferShareActionsAdapter,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService
  ) { }

  ngOnChanges(): void {
    if (this.inputData) {
      this.initData();
    }
  }

  private initData() {
    this.brandingService.getBranding()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.data = this.adapter.adaptShareActionsData(this.inputData, branding);
      });
  }

  onClickGenerateEmailOffer() {
    GenerateEmailOfferComponent.show(this.dialog, this.data);
  }

  onClickSendBankLink() {
    SendBankLinkComponent.show(this.dialog, this.data).subscribe(email => {
      if (!email) {
        return;
      }

      this.submissionFacade.sendBankLink(this.data.appId, email).subscribe(() => {
        const dialogData: DialogActionData = bankLinkSentDialogData(email);
        return DialogActionComponent.show(this.dialog, dialogData);
      })
    })
  }

  onClickCopyOfferLink(): void {
    DomHelper.copy(this.data.summaryPreviewUrl);
    this.messageService.info('Link copied');
  }

  onClickSeeCustomerOfferView(): void {
    window.open(this.data.summaryPreviewUrl, "_blank");
  }
}
