<section class="container">
  <ifbp-not-interested-action [data]="notInterestedActionData"
                              (actionComplete)="onNotInterestedActionComplete($event)">
  </ifbp-not-interested-action>
  <div class="actions-right">
    <button mat-button
            preventDoubleClicks
            (singleClick)="onClickClose()">CLOSE</button>
    <ifc-app-bar-actions *ngIf="appBarActions?.length > 0"
                         [menuMobileAllowed]="false">
    </ifc-app-bar-actions>
  </div>
</section>
