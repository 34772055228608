import { Component, Input } from '@angular/core';
import { TransactionStatus } from 'common';
import { LoanInitialDrawData } from 'projects/partner/src/app/feature-modules/submissions/domain/models/loan.model';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';

@Component({
  selector: 'ifbp-activated-details-draw-section',
  templateUrl: './activated-details-draw-section.component.html',
  styleUrls: ['./activated-details-draw-section.component.scss']
})
export class ActivatedDetailsDrawSectionComponent {
  readonly TransactionStatus = TransactionStatus;
  readonly BrokerStatus = BrokerStatus;

  @Input()
  brokerStatus: BrokerStatus;

  @Input()
  transactionStatusLabel: Map<TransactionStatus, string>;;

  @Input()
  drawData?: LoanInitialDrawData;
}
