import { Injectable } from "@angular/core";
import { ApplicationOffer, ButtonColor, CompanyBranding, DialogActionData, PaymentFrequency, ProductCode } from "common";
import { OfferShareActionsData, OfferShareActionsInputData, OfferShareActionsOfferPreviewData } from "./offer-share-actions.model";
import { SubmissionDetailsBaseAdapter } from "../../../submission-details.data";

@Injectable()
export class OfferShareActionsAdapter {
  constructor(
    private readonly submissionBaseAdapter: SubmissionDetailsBaseAdapter,
  ) { }

  adaptShareActionsData(inputData: OfferShareActionsInputData, branding: CompanyBranding): OfferShareActionsData {
    const offers: OfferShareActionsOfferPreviewData[] = inputData.filteredOfferBundles.map(offerBundle => {
      const offer = offerBundle.offers?.[0] as ApplicationOffer;
      return {
        productCode: <ProductCode>offer.productCode,
        amount: <number>offer.amount,
        repaymentTerm: <number>offer.repaymentTerm,
        paymentFrequency: <PaymentFrequency>offer.paymentFrequency,
      }
    })

    const app = inputData.app;
    const appUuid = <string>app.uuid;

    return {
      appId: <number>app.id,
      appUuid,
      accountName: <string>app.accountName,
      offers,
      contactEmails: app.contactEmails,
      summaryPreviewUrl: this.submissionBaseAdapter.getSummaryPreviewUrl(appUuid),
      companyName: branding?.dba ?? "",
    }
  }

}

export const bankLinkSentDialogData = (email: string): DialogActionData => {
  return {
    body: `Bank link was successfully sent to ${email}.`,
    title: "Bank link sent",
    buttons: [
      {
        label: "OK",
        buttonColor: ButtonColor.Primary,
        action: true,
      }
    ]
  }
}
