import { Component, Input } from '@angular/core';
import { ProductCode, ProductCodeLabel } from 'common';

@Component({
  selector: 'ifbp-product-name-section-header',
  templateUrl: './product-name-section-header.component.html',
  styleUrls: ['./product-name-section-header.component.scss']
})
export class ProductNameSectionHeaderComponent {
  readonly ProductCode = ProductCode;
  readonly ProductCodeLabel = ProductCodeLabel;

  @Input()
  productCode: ProductCode;

  @Input()
  type: 'primary' | 'secondary' = 'primary';
}
