<ng-container *ngIf="drawData && [BrokerStatus.OPEN, BrokerStatus.FUNDED, BrokerStatus.CLOSED].includes(brokerStatus)">
  <section class="container">
    <div class="column">
      <p class="column__item column__item--label">Draw amount</p>
      <p class="column__item column__item--value"
         *ngIf="drawData?.totalAmount || drawData?.totalAmount === 0; else notAvailable">
        {{drawData.totalAmount | currency}}
      </p>
    </div>
    <div class="column">
      <p class="column__item column__item--label">Draw date</p>
      <p class="column__item column__item--value"
         *ngIf="drawData?.transactionDate; else notAvailable">
        {{drawData.transactionDate | date}}
      </p>
    </div>
    <div class="column">
      <p class="column__item column__item--label">Disbursed amount</p>
      <p class="column__item column__item--value"
         *ngIf="drawData?.disbursedAmount || drawData?.disbursedAmount === 0; else notAvailable">
        {{drawData.disbursedAmount | currency}}
      </p>
    </div>
    <div class="column">
      <p class="column__item column__item--label">Status</p>
      <p class="column__item column__item--value"
         *ngIf="drawData?.status || drawData?.status === 0; else notAvailable">
        {{drawData.status | enumValueLabel : TransactionStatus : transactionStatusLabel}}
      </p>
    </div>
    <ng-template #notAvailable>
      <p class="column__item column__item--value">N/A</p>
    </ng-template>
  </section>
</ng-container>
