import { NgModule } from "@angular/core";
import { SubmissionsRoutingModule } from "./submissions-routing.module";
import { SubmissionsSharedModule } from "./shared/submissions-shared.module";
import { NotInterestedActionModule } from "./features/not-interested-action/not-interested-action.module";
import { NotInterestedDialogModule } from "./features/not-interested-dialog/not-interested-dialog.module";
import { SubmissionListModule } from "./features/submission-list/submission-list.module";
import { SubmissionsDetailsModule } from "./features/submission-details/submission-details.module";
@NgModule({
  imports: [
    SubmissionsRoutingModule,
    SubmissionsSharedModule,
    NotInterestedActionModule,
    NotInterestedDialogModule,
    SubmissionListModule,
    SubmissionsDetailsModule
  ],
  exports: [
    NotInterestedActionModule,
    NotInterestedDialogModule,
    SubmissionListModule,
    SubmissionsDetailsModule
  ]
})
export class SubmissionsModule { }
