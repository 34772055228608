
import { NgModule } from "@angular/core";
import { SubmissionDetailsSharedComponentsModule } from "./components/submission-details-shared-components.module";
import { OfferRequirementsSectionModule } from "./modules/offer-requirements-section/offer-requirements-section.module";
import { OfferDetailsSectionModule } from "./modules/offer-details-section/offer-details-section.module";
import { OfferShareActionsModule } from "./modules/offer-share-actions/offer-share-actions.module";
import { OfferDetailsPanelModule } from "./modules/offer-details-panel/offer-details-panel.module";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule,
    OfferRequirementsSectionModule,
    OfferDetailsSectionModule,
    OfferShareActionsModule,
    OfferDetailsPanelModule
  ],

  exports: [
    SubmissionDetailsSharedComponentsModule,
    OfferRequirementsSectionModule,
    OfferDetailsSectionModule,
    OfferShareActionsModule,
    OfferDetailsPanelModule
  ]
})
export class SubmissionDetailsSharedModule { }
