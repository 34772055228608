<ng-container *ngIf="data">
  <mat-expansion-panel #panel="matExpansionPanel"
                       class="panel-container"
                       [ngClass]="{'panel-container--selected': data.isMarkedAsSelected}"
                       [disabled]="data.isRejected"
                       hideToggle
                       (opened)="panelToggled(true)"
                       (closed)="panelToggled(false)">
    <mat-expansion-panel-header class="panel-header"
                                collapsedHeight="72px"
                                expandedHeight="76px">
      <div class="header-container"
           (click)="$event.stopPropagation();">
        <div class="header">
          <button *ngIf="data.isSelectable"
                  [disabled]="data.isRejected"
                  mat-stroked-button
                  preventDoubleClicks
                  class="select-action"
                  [ngClass]="{'select-action--disabled': data.isRejected}"
                  (singleClick)="onClickSelect(data)">
            SELECT
          </button>
          <div class="header-text"
               [ngClass]="{'header-text--disabled': data.isRejected}"
               (click)="onClickPanel(data)">
            <span class="header-text__item">{{data.headerData?.formattedAmount}}</span>
            <span class="header-text__item">{{data.headerData?.formattedTerm}}</span>
            <span class="header-text__item header-text__item--payments">{{data.headerData?.formattedPaymentFrequency}}</span>
          </div>
          <ng-container *ngIf="data.isRejectable">
            <button *ngIf="!data.isRejected"
                    mat-icon-button
                    matTooltip="Press to hide offer"
                    preventDoubleClicks
                    class="hide-action"
                    (singleClick)="onClickReject(data)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button *ngIf="data.isRejected"
                    mat-icon-button
                    matTooltip="Press to unhide offer"
                    preventDoubleClicks
                    class="hide-action"
                    (singleClick)="onClickReactivate(data)">
              <mat-icon>visibility_off</mat-icon>
            </button>
          </ng-container>
        </div>
        <button [disabled]="data.isRejected"
                [ngClass]="{'hidden-details-action': data.isRejected}"
                mat-icon-button
                (click)="onClickPanel(data)">
          <mat-icon>{{isPanelOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}} </mat-icon>
        </button>
      </div>
    </mat-expansion-panel-header>
    <div class="content">
      <ifbp-submission-details-basic-section [rows]="data?.rows"
                                             [displayMode]="DisplayMode.Narrow">
      </ifbp-submission-details-basic-section>
      <p *ngIf="data.footerText"
         class="footerText">
        {{data.footerText}}
      </p>
      <button *ngIf="data.detailsPreviewUrl"
              class="redirect-action"
              mat-button
              color="primary"
              (click)="onCustomerOfferViewClick(data.detailsPreviewUrl)">
        <div class="redirect-action__body">
          <mat-icon class="redirect-action__icon">open_in_new</mat-icon>
          <span>CUSTOMER OFFER VIEW</span>
        </div>
      </button>
      <div *ngIf="data.requirementNames"
           class="requirements">
        <p class="requirements__heading">Closing Documents and Next steps</p>
        <ul class="requirements__list">
          <li *ngFor="let requirementName of data.requirementNames">
            {{requirementName}}
          </li>
        </ul>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
