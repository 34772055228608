<section class="skeleton-loader"
         *ngIf="!isDataLoaded">
  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>
  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>

  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>
</section>

<ng-container *ngIf="brokerStatus">
  <ng-container *ngIf="[BrokerStatus.PROCESSING, BrokerStatus.NOT_INTERESTED, BrokerStatus.DORMANT, BrokerStatus.DECLINED].includes(brokerStatus)">
    <ifbp-processing-details [params]="params"></ifbp-processing-details>
  </ng-container>
  <ng-container *ngIf="[BrokerStatus.OPEN, BrokerStatus.FUNDED, BrokerStatus.CLOSED, BrokerStatus.ABANDONED].includes(brokerStatus)">
    <ifbp-activated-details [params]="params"></ifbp-activated-details>
  </ng-container>
  <ng-container *ngIf="[BrokerStatus.CONTRACT_READY, BrokerStatus.CONTRACT_OUT].includes(brokerStatus)">
    <ifbp-contract-details [params]="params"></ifbp-contract-details>
  </ng-container>
  <ng-container *ngIf="[BrokerStatus.CLOSING, BrokerStatus.CLOSING_INCOMPLETE].includes(brokerStatus)">
    <ifbp-closing-details [params]="params"></ifbp-closing-details>
  </ng-container>
  <ng-container *ngIf="[BrokerStatus.OFFER, BrokerStatus.CONDITIONAL_OFFER].includes(brokerStatus)">
    <ifbp-offer-details [params]="params"></ifbp-offer-details>
  </ng-container>
</ng-container>
