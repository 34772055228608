import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ApplicationStage } from "common";
import { SubmissionsFacade } from "../../domain/+state/submissions.facade";
import { SubmissionDetailsBaseComponentData } from "./submission-details.model";
import { ApplicationData } from "../../domain/models/application.model";

@Injectable()
export class SubmissionDetailsFacade {
  constructor(
    private readonly submissionsFacade: SubmissionsFacade
  ) { }

  getComponentBaseData$(applicationId: number): Observable<SubmissionDetailsBaseComponentData> {
    return this.submissionsFacade.getApplicationData$(applicationId)
      .pipe(switchMap((application: ApplicationData) => {
        const notInterestedActionData = this.initNotInterestedActionData(application);
        if (!application?.accountId) {
          return of({
            application,
            account: undefined,
            notInterestedActionData,
          })
        }
        return this.submissionsFacade.getAccountData(application.accountId).pipe(map((account) => ({
          application,
          account,
          notInterestedActionData,
        })))
      }));
  }

  private initNotInterestedActionData(data: ApplicationData) {
    return {
      applicationId: <number>data.id,
      brokerStatus: data.brokerStatus,
      createOnDate: new Date(data.createdOn as unknown as string),
      previousStage: <ApplicationStage>data?.previousStage
    }
  }
}
