import { ApplicationBaseData, ApplicationStage } from "common";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { ApplicationData } from "../../domain/models/application.model";

export interface ApplicationResponse extends ApplicationBaseData {
  requestedAmount?: number;
  incompleteInfo?: string;
  declinedReason?: string;
  previousStage?: ApplicationStage;
  brokerStatus: BrokerStatus;
  lostReason?: string;
  lostReasonAdditionalComments?: string;
}

export const adaptApplicationData = (data: ApplicationResponse): ApplicationData => {
  return {
    ...data,
    advisor: {
      firstName: <string>data.advisorFirstName,
      lastName: <string>data.advisorLastName,
      fullName: <string>data.advisorName,
      phone: <string>data.advisorPhone,
      email: <string>data.advisorEmail,
      calendarLinkUrl: <string>data.advisorCalendarLinkUrl,
    }
  }
}
