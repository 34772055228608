import { Component, Input } from '@angular/core';
import { SubmissionDetailsHeaderData } from '../../../submission-details.model';

@Component({
  selector: 'ifbp-submission-details-header-section',
  templateUrl: './submission-details-header-section.component.html',
  styleUrls: ['./submission-details-header-section.component.scss']
})
export class SubmissionDetailsHeaderSectionComponent {
  @Input()
  data: SubmissionDetailsHeaderData;
}
