import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubmissionsFacade } from "../../../../domain/+state/submissions.facade";
import { SubmissionDetailsFacade } from "../../submission-details.facade";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { filterOfferBundles, getSelectedOfferBundle } from "../../submission-details.data";
import { getOfferDetailsAppBarActions, getOfferDetailsHeaderData, getSubmitConfirmationDialogData, OfferSubmissionAdapter } from "./offer-details.data";
import { OfferDetailsComponentData } from "./offer-details.model";
import { ApplicationStage, DialogActionComponent, DialogActionData, MessageService, OfferBundleCheckoutRequirement, OfferBundleStatus } from "common";
import { MatDialog } from "@angular/material/dialog";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData, UpdateOfferBundleStatusData } from "../../../../domain/models/submissions.model";
import { OfferDetailsSectionAdapter } from "../../shared/modules/offer-details-section/offer-details-section.data";

@Injectable()
export class OfferDetailsFacade {
  constructor(
    private readonly detailsFacade: SubmissionDetailsFacade,
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly offerSubmissionAdapter: OfferSubmissionAdapter,
    private readonly offerDetailsSectionAdapter: OfferDetailsSectionAdapter,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService,
  ) { }

  getComponentData$(applicationId: number): Observable<OfferDetailsComponentData> {
    return this.detailsFacade.getComponentBaseData$(applicationId)
      .pipe(switchMap((data: SubmissionDetailsBaseComponentData) => {
        return this.getOfferDetailsComponentData$(data);
      }));
  }

  private getOfferDetailsComponentData$(data: SubmissionDetailsBaseComponentData): Observable<OfferDetailsComponentData> {
    return this.submissionsFacade.getAdvisorData(data.application.id)
      .pipe(switchMap((advisorData: AdvisorData) => {
        const selectedOfferBundleId = getSelectedOfferBundle(data.application)?.id;
        const commonData: OfferDetailsComponentData = {
          ...data,
          headerData: getOfferDetailsHeaderData(data.application.brokerStatus, data.account?.name),
          advisorData,
        }

        if (!selectedOfferBundleId) {
          return this.getOfferListStepData(commonData);
        }

        return this.getSelectedOfferStepData(commonData, selectedOfferBundleId);
      }))
  }

  private getOfferListStepData(data: OfferDetailsComponentData): Observable<OfferDetailsComponentData> {
    const filteredOfferBundles = filterOfferBundles(data?.application?.offerBundles);

    if (!filteredOfferBundles?.length) {
      return of(data);
    }

    const offerBundleIds = filteredOfferBundles.map((offerBundle) => offerBundle.id);
    return this.submissionsFacade.getCheckoutRequirementsByOfferBundles(offerBundleIds).pipe(map((summaryList) => {
      return {
        ...data,
        offerListData: this.offerSubmissionAdapter.adaptOfferListSectionData(data.application, filteredOfferBundles, summaryList),
        offerRequirementsData: this.offerSubmissionAdapter.adaptEmptyOfferRequirementsSectionData(),
        appBarActions: getOfferDetailsAppBarActions(true),
      }
    }));
  }

  private getSelectedOfferStepData(data: OfferDetailsComponentData, selectedOfferBundleId: number): Observable<OfferDetailsComponentData> {
    return this.submissionsFacade.getCheckoutRequirements$(selectedOfferBundleId)
      .pipe(map((checkoutRequirements: OfferBundleCheckoutRequirement[]) => ({
        ...data,
        selectedOfferBundleId: <number>selectedOfferBundleId,
        offerDetailsData: this.offerDetailsSectionAdapter.adaptOfferDetailsSectionData(data.application, true),
        offerRequirementsData: this.offerSubmissionAdapter.adaptOfferRequirementsSectionData(checkoutRequirements),
        appBarActions: getOfferDetailsAppBarActions(false),
      })))
  }

  removeCheckoutRequirementDocument(offerBundleId: number, data: RemoveCheckoutRequirementDocumentData) {
    this.submissionsFacade.removeCheckoutRequirementDocument(offerBundleId, data).subscribe();
  }

  downloadCheckoutRequirementDocument(data: DownloadCheckoutRequirementDocumentData): void {
    this.submissionsFacade.downloadCheckoutRequirementDocument(data);
  }

  saveCheckoutRequirementDocuments(offerBundleId: number, documentsToSave: SaveCheckoutRequirementDocumentData[]): void {
    this.submissionsFacade.saveCheckoutRequirementDocuments(offerBundleId, documentsToSave).subscribe();
  }

  updateOfferBundleStatus(data: UpdateOfferBundleStatusData, viewData: OfferDetailsComponentData) {
    if (!this.isUpdateOfferStatusPossible(data, viewData)) {
      this.messageService.warning("There must be at least one visible offer.");
      return;
    }
    this.submissionsFacade.updateOfferBundleStatus(data).subscribe();
  }

  private isUpdateOfferStatusPossible(data: UpdateOfferBundleStatusData, viewData: OfferDetailsComponentData): boolean {
    if (data.status !== OfferBundleStatus.Rejected) {
      return true;
    }

    const availableLocOffers = viewData.offerListData?.offerPanelsData?.locOffers?.filter(offer => !offer.isRejected) ?? [];
    const availableTermOffers = viewData.offerListData?.offerPanelsData?.termOffers?.filter(offer => !offer.isRejected) ?? [];
    const otherAvailableOffers = [...availableLocOffers, ...availableTermOffers].filter(offer => offer.offerBundleId !== data.offerBundleId);

    return otherAvailableOffers.length > 0;
  }

  submitForReview(appId: number, brokerStatus: BrokerStatus, advisorData: AdvisorData): Observable<boolean> {
    const nextStage = brokerStatus === BrokerStatus.CONDITIONAL_OFFER ? ApplicationStage.Underwriting : ApplicationStage.ClosingReview;
    return this.submissionsFacade.updateApplicationStage(appId, { stage: nextStage })
      .pipe(switchMap(() => this.openConfirmationDialog(advisorData)));
  }

  private openConfirmationDialog(advisorData: AdvisorData) {
    const dialogData: DialogActionData = getSubmitConfirmationDialogData(advisorData);
    return DialogActionComponent.show(this.dialog, dialogData)
  }
}
