import { Injectable } from "@angular/core";
import { EMPTY, Observable, combineLatest, forkJoin, of } from "rxjs";
import { SubmissionsRemote } from "../../infrastructure/submissions.remote";
import { MessageService, OfferBundleCheckoutRequirement, OfferBundleCheckoutRequirementService, OfferBundleCheckoutRequirementsSummary } from "common";
import { SubmissionDetailsService } from "./submission-details.service";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData, SubmissionQueryParams, SubmissionsHintsQueryParams, SubmissionsQueryResult, UpdateOfferBundleStatusData } from "../models/submissions.model";
import { NotInterestedDialogData } from "../../features/not-interested-dialog/not-interested-dialog.model";
import { DictionariesFacade } from "../../../dictionaries/domain/+state/dictionaries.facade";
import { CompetitorsFacade } from "../../../competitors/domain/+state/competitors.facade";
import { catchError, map, mapTo, switchMap, take, tap } from "rxjs/operators";
import { AccountData, ApplicationData, ApplicationDocumentsQueryResult, ContactQueryResult, ContactsQueryParams, UpdateApplicationPayload } from "../models/application.model";
import { SubmissionsStateService } from "./submissions.state.service";
import { LoanData } from "../models/loan.model";
import { AccountFacade } from "../../../account/domain/+state/account.facade";
import { AccountSummary } from "../../../account/domain/model/account.model";
import { AdvisorData } from "../../shared/models/advisor.model";
import { defaultObservableErrorHandler } from "projects/partner/src/Tools";

@Injectable({
  providedIn: "root",
})
export class SubmissionsFacade {
  constructor(
    private readonly remote: SubmissionsRemote,
    private readonly submissionDetailsService: SubmissionDetailsService,
    private readonly lostReasonsFacade: DictionariesFacade,
    private readonly competitorsFacade: CompetitorsFacade,
    private readonly messageService: MessageService,
    private readonly stateService: SubmissionsStateService,
    private readonly accountFacade: AccountFacade,
    private readonly checkoutRequirementService: OfferBundleCheckoutRequirementService
  ) { }

  getApplicationData$(id: number): Observable<ApplicationData> {
    if (this.stateService.isApplicationDataLoaded(id)) {
      return this.stateService.getApplicationData$();
    }

    return this.refreshApplicationData(id).pipe(switchMap(() => this.stateService.getApplicationData$()));
  }

  getApplicationData(id: number): Observable<ApplicationData> {
    return this.getApplicationData$(id).pipe(take(1));
  }

  private refreshApplicationData(id: number): Observable<void> {
    return this.remote.getApplicationData(id).pipe(
      tap((application: ApplicationData) => this.stateService.setApplicationData(application)),
      mapTo(undefined),
    );
  }

  updateApplicationStage(
    applicationId: number,
    payload: UpdateApplicationPayload,
  ): Observable<ApplicationData> {
    return this.remote.updateApplicationStage(applicationId, payload)
      .pipe(tap(() => this.clearSubmissionCache()));
  }

  goToSubmissionDetails(submissionId: number, brokerStatus: BrokerStatus) {
    return this.submissionDetailsService.goToSubmissionDetails(submissionId, brokerStatus);
  }

  getNotInterestedDialogData(): Observable<NotInterestedDialogData> {
    return combineLatest([this.lostReasonsFacade.getLostReasons$(),
    this.competitorsFacade.getCompetitors$()]).pipe(
      map(([lostReasons, competitors]) => ({
        notInterestedReasons: lostReasons,
        competitors: competitors?.filter(competitor =>
          competitor.apiName)
      }))
    );
  }

  getSubmissions(params: SubmissionQueryParams): Observable<SubmissionsQueryResult> {
    return this.remote.getSubmissions(params).pipe(catchError(error => {
      this.messageService.error(error?.error?.Message);
      return EMPTY;
    }));
  }

  getSubmissionsHints(params: SubmissionsHintsQueryParams): Observable<string[]> {
    return this.remote.getSubmissionsHints(params);
  }

  getAccountData(id: number): Observable<AccountData> {
    return this.remote.getAccountData(id);
  }

  getApplicationDocuments(id: number): Observable<ApplicationDocumentsQueryResult> {
    return this.remote.getApplicationDocuments(id);
  }

  getContactsData(params: ContactsQueryParams): Observable<ContactQueryResult> {
    return this.remote.getContactsData(params);
  }

  downloadApplicationDocument(applicationId: number, documentId: number, filename: string): void {
    return this.remote.downloadApplicationDocument(applicationId, documentId, filename);
  }

  getLoanData(id: number): Observable<LoanData> {
    return this.remote.getLoanData(id);
  }

  getAdvisorData(applicationId?: number): Observable<AdvisorData> {
    return this.getAdvisorData$(applicationId).pipe(take(1));
  }

  getAdvisorData$(applicationId?: number): Observable<AdvisorData> {
    if (!applicationId) {
      return this.getAdvisorDataFromBroker$();
    }

    return this.getApplicationData$(applicationId)
      .pipe(switchMap((data: ApplicationData) => {
        if (data.advisor?.fullName?.trim()) {
          return of(data.advisor)
        }
        return this.getAdvisorDataFromBroker$();
      }));
  }

  private getAdvisorDataFromBroker$(): Observable<AdvisorData> {
    return this.accountFacade.getCurrentAccountSummary$()
      .pipe(map((data: AccountSummary) => data.broker.advisor));
  }

  private clearSubmissionCache(): void {
    return this.stateService.clearState();
  }

  sendCheckout(appId: number): Observable<void> {
    return this.remote.sendCheckout(appId).pipe(tap(() => this.clearSubmissionCache()));
  }

  resendCheckout(appId: number): Observable<void> {
    return this.remote.resendCheckout(appId).pipe(tap(() => this.clearSubmissionCache()));
  }

  sendBankLink(appId: number, email: string): Observable<void> {
    return this.remote.sendBankLink(appId, email);
  }

  getCheckoutRequirements$(offerBundleId: number): Observable<OfferBundleCheckoutRequirement[]> {
    if (this.stateService.isCheckoutRequirementsDataLoaded(offerBundleId)) {
      return this.stateService.getCheckoutRequirementsData$();
    }

    return this.refreshCheckoutRequirements(offerBundleId).pipe(
      switchMap(() => this.stateService.getCheckoutRequirementsData$()))
  }

  getCheckoutRequirements(offerBundleId: number): Observable<OfferBundleCheckoutRequirement[]> {
    return this.getCheckoutRequirements$(offerBundleId).pipe(take(1));
  }

  private refreshCheckoutRequirements(offerBundleId: number): Observable<void> {
    return this.checkoutRequirementService.getList(offerBundleId).pipe(
      tap((data: OfferBundleCheckoutRequirement[]) => this.stateService.setCheckoutRequirementsData(offerBundleId, data)),
      mapTo(undefined)
    );
  }

  getCheckoutRequirementsByOfferBundles(offerBundleIds: number[]): Observable<OfferBundleCheckoutRequirementsSummary[]> {
    return this.checkoutRequirementService.getListSummary(offerBundleIds)
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  removeCheckoutRequirementDocument(offerBundleId: number, data: RemoveCheckoutRequirementDocumentData): Observable<void> {
    return this.remote.removeCheckoutRequirementDocument(data)
      .pipe(switchMap(() => this.refreshCheckoutRequirements(offerBundleId)))
  }

  downloadCheckoutRequirementDocument(data: DownloadCheckoutRequirementDocumentData): void {
    return this.remote.downloadCheckoutRequirementDocument(data);
  }

  saveCheckoutRequirementDocuments(offerBundleId: number, documentsToSave: SaveCheckoutRequirementDocumentData[]): Observable<void> {
    const documentsRequests = documentsToSave.flatMap(data => {
      return this.remote.saveCheckoutRequirementDocument(data);
    });

    return forkJoin(documentsRequests).pipe(switchMap(() => this.refreshCheckoutRequirements(offerBundleId)));
  }

  updateOfferBundleStatus(data: UpdateOfferBundleStatusData): Observable<void> {
    return this.remote.updateOfferBundleStatus(data.offerBundleId, data.status)
      .pipe(switchMap(() => this.refreshState(data.applicationId, data.offerBundleId)))
  }

  private refreshState(applicationId: number, offerBundleId: number): Observable<void> {
    this.clearSubmissionCache();
    return forkJoin([this.refreshApplicationData(applicationId), this.refreshCheckoutRequirements(offerBundleId)])
      .pipe(mapTo(undefined));
  }
}
