import { BrokerData } from "../domain/model/account.model";

export interface BrokerResponse {
  id: number;
  advisorId: number;
  advisorFirstName: string;
  advisorLastName: string;
  advisorName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorCalendarLinkUrl: string;
  advisorAvatar: string;
  name: string;
}

export const adaptBrokerData = (data: BrokerResponse): BrokerData => {
  return {
    id: data.id,
    name: data.name,
    advisor: {
      id: data.advisorId,
      firstName: data.advisorFirstName,
      lastName: data.advisorLastName,
      fullName: data.advisorName,
      phone: data.advisorPhone,
      email: data.advisorEmail,
      calendarLinkUrl: data.advisorCalendarLinkUrl,
      avatar: data.advisorAvatar,
    }
  }
}
