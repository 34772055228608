import { NgModule } from "@angular/core";
import { ActivatedDetailsComponent } from "./activated-details.component";
import { ActivatedDetailsLoanSectionComponent } from './components/activated-details-loan-section/activated-details-loan-section.component';
import { ActivatedDetailsDrawSectionComponent } from './components/activated-details-draw-section/activated-details-draw-section.component';
import { SubmissionDetailsSharedModule } from "../../shared/submission-details-shared.module";

@NgModule({
  imports: [
    SubmissionDetailsSharedModule,
  ],
  declarations: [
    ActivatedDetailsComponent,
    ActivatedDetailsLoanSectionComponent,
    ActivatedDetailsDrawSectionComponent
  ],
  exports: [ActivatedDetailsComponent]
})
export class ActivatedDetailsModule { }
