import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from '../../../../not-interested-action/not-interested-action.model';
import { SubmissionDetailsActionsService } from '../../../actions/submission-details-actions.service';
import { AppBarAction, AppBarActionsService } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifbp-submission-details-footer-section',
  templateUrl: './submission-details-footer-section.component.html',
  styleUrls: ['./submission-details-footer-section.component.scss']
})
export class SubmissionDetailsFooterSectionComponent implements OnInit {
  @Input()
  notInterestedActionData: NotInterestedActionInputData;

  appBarActions: AppBarAction[]
  @Input()
  set appBarActionsData(value: AppBarAction[]) {
    this.appBarActions = value;
    this.appBarActionsService.actions = value;
  }

  @Output()
  appBarActionInvoke: EventEmitter<AppBarAction> = new EventEmitter();

  constructor(
    private readonly submissionDetailsActionsService: SubmissionDetailsActionsService,
    private readonly appBarActionsService: AppBarActionsService) { }

  ngOnInit(): void {
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe((action: AppBarAction) => this.appBarActionInvoke.emit(action));
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    this.submissionDetailsActionsService.notInterestedActionComplete(data);
  }

  onClickClose() {
    this.submissionDetailsActionsService.close();
  }
}
