import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationDocumentData } from 'projects/partner/src/app/feature-modules/submissions/domain/models/application.model';

@Component({
  selector: 'ifbp-processing-details-documents-section',
  templateUrl: './processing-details-documents-section.component.html',
  styleUrls: ['./processing-details-documents-section.component.scss']
})
export class ProcessingDetailsDocumentsSectionComponent {
  @Input()
  bankStatementDocuments: ApplicationDocumentData[];

  @Input()
  signedApplicationDocuments: ApplicationDocumentData[];

  @Output()
  downloadDocument = new EventEmitter<ApplicationDocumentData>();

  onFileNameClick(data: ApplicationDocumentData) {
    this.downloadDocument.emit(data);
  }
}
