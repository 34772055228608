import { MatPaginator } from "@angular/material/paginator";
import {
  AppSidenavItem,
  MessageService,
  ObjectHelper,
  QueryParams,
  SimpleDocumentFile,
} from "common";
import {
  ApplicationFile,
  UppyInput,
} from "./app/submission/models/submission.model";
import { UppyFile } from "@uppy/core";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

type NullPart<T> = T & (null | undefined);
export type MustBeAmbiguouslyNullable<T> = NullPart<T> extends never
  ? never
  : NonNullable<T> extends never
  ? never
  : T;

export function hasValue<T>(
  value: MustBeAmbiguouslyNullable<T>,
): value is NonNullable<MustBeAmbiguouslyNullable<T>> {
  return (value as unknown) !== undefined && (value as unknown) !== null;
}

export function hasValueFn<T, A>(
  value: MustBeAmbiguouslyNullable<T>,
  thenFn: (value: NonNullable<MustBeAmbiguouslyNullable<T>>) => A,
): A | NullPart<T> {
  return hasValue(value)
    ? thenFn(value)
    : (value as NullPart<MustBeAmbiguouslyNullable<T>>);
}

export function addDefaultAppSidenavItemOptions(
  items: (Omit<AppSidenavItem, "hidden"> & { hidden?: boolean })[],
  defaultOptions: {
    hidden?: boolean;
    query?: {
      sort?: string;
    };
  } = {
      hidden: false,
      query: {
        sort: undefined,
      },
    },
): AppSidenavItem[] {
  return items.map(item => ({
    ...defaultOptions,
    ...item,
    query: { ...defaultOptions.query, ...item.query },
    subitems: item.subitems?.map(subitem => ({
      ...defaultOptions,
      ...subitem,
      query: { ...defaultOptions.query, ...subitem.query },
    })),
  })) as AppSidenavItem[];
}

export function setQueryVarsFromPaginator(
  query: QueryParams,
  paginator: MatPaginator,
): void {
  query.limit = paginator.pageSize;
  query.skip = paginator.pageIndex * paginator.pageSize;
}

export function setPaginatorFromQueryVars(paginator: MatPaginator, query: any) {
  paginator.pageSize = query.limit;
  paginator.pageIndex = Math.floor(query.skip / query.limit);
}

export function getUppyInputFromFile(
  document: ApplicationFile | SimpleDocumentFile,
  blob: Blob,
): UppyInput {
  return {
    name: document.filename,
    data: blob,
    size: blob.size,
    type: document["mimeType"],
    meta: {
      isExisting: true,
      id: document.id,
    },
  };
}

export function convertToExistingFile(newFile: UppyFile, id?: number): UppyInput {
  return {
    ...newFile,
    type: newFile.type as string,
    meta: {
      isExisting: true,
      id: id || null,
    },
  };
}

export function getErrorForMessageService(error: HttpErrorResponse | string) {
  if (error instanceof HttpErrorResponse && error.status === 0) {
    return "Please try again.";
  } else {
    return error;
  }
}

export function checkDeepEmpty(object: object): boolean {
  if (!(object instanceof Object)) {
    return ObjectHelper.isEmpty(object, true);
  }
  return Object.values(object).every(value => {
    if (value instanceof Object) {
      return checkDeepEmpty(value);
    }
    return ObjectHelper.isEmpty(object, true);
  });
}

export function defaultObservableError(
  error: HttpErrorResponse | string,
  messageService: MessageService,
) {
  messageService.error(getErrorForMessageService(error));
  return throwError(error);
}

export function defaultObservableErrorHandler(messageService: MessageService) {
  return (error: HttpErrorResponse | string) =>
    defaultObservableError(error, messageService);
}
