import { ApplicationOfferBundleData, OfferBundleStatus, ProductCode, StringHelper } from "common";
import { ApplicationData } from "../../domain/models/application.model";
import { AdvisorData } from "../../shared/models/advisor.model";
import { AppSettings } from "projects/partner/src/app.settings";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

export const getContactString = (advisorData: AdvisorData): string => {
  if (!advisorData?.fullName || (!advisorData?.email && !advisorData?.phone)) {
    return '';
  }
  const advisorEmailFormat = advisorData?.email ? `<a href="mailto:${advisorData.email}">${advisorData.email}</a>` : '';
  const advisorPhoneFormat = advisorData?.phone ? `<a href="tel:${advisorData.phone}">${StringHelper.phoneFormat(advisorData.phone)}</a>` : '';

  if (!advisorData?.email) {
    return `Contact ${advisorData.fullName} at ${advisorPhoneFormat} if you have any questions.`;
  }

  if (!advisorData?.phone) {
    return `Contact ${advisorData.fullName} at ${advisorEmailFormat} if you have any questions.`;
  }

  return `Contact ${advisorData.fullName} at ${advisorEmailFormat} or ${advisorPhoneFormat} if you have any questions.`;
}

export const getSelectedOfferBundle = (app: ApplicationData): ApplicationOfferBundleData | undefined => {
  return app?.offerBundles?.filter(offerBundle => offerBundle.status === OfferBundleStatus.Accepted)?.at(0);
}

export const filterOfferBundles = (offerBundles?: ApplicationOfferBundleData[]): ApplicationOfferBundleData[] | undefined => {
  const filteredOffers = offerBundles?.filter((offerBundle) => {
    return !!offerBundle?.offers?.length &&
      offerBundle.status !== OfferBundleStatus.Expired &&
      (offerBundle.offers[0].productCode === ProductCode.LOC ||
        offerBundle.offers[0].productCode === ProductCode.Term)
  });

  return filteredOffers?.sort((a, b) => <number>b.offers![0].amount - <number>a.offers![0].amount);
}

export const filterShareableOfferBundles = (offerBundles?: ApplicationOfferBundleData[]): ApplicationOfferBundleData[] | undefined => {
  return filterOfferBundles(offerBundles)?.filter((offerBundle) => offerBundle.status !== OfferBundleStatus.Rejected);
}

@Injectable()
export class SubmissionDetailsBaseAdapter {
  constructor(
    private readonly appSettings: AppSettings,
    private readonly router: Router,
  ) { }

  getSummaryPreviewUrl(appUuid: string): string {
    const appRootUrl = this.appSettings.projectsRootSiteUrls.application;
    const summaryPath = this.router.createUrlTree(["summary"], { queryParams: { uuid: appUuid } }).toString();
    return `${appRootUrl}/preview${summaryPath}`;
  }

  getDetailsPreviewUrl(appUuid: string, productCode: ProductCode, offerBundleId: number): string {
    const appRootUrl = this.appSettings.projectsRootSiteUrls.application;
    const pathName = productCode === ProductCode.LOC ? "loc" : "term";
    const path = this.router.createUrlTree([pathName], { queryParams: { uuid: appUuid, bundleId: offerBundleId } }).toString();
    return `${appRootUrl}/preview${path}`;
  }
}
