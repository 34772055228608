import { Component, OnInit } from '@angular/core';
import { BrokerApiKeysFacade } from '../../domain/+state/broker-api-keys-facade';
import { BrokerApiKeyListFacade } from './broker-api-key-list.facade';
import { MatPaginator } from '@angular/material/paginator';
import { BrokerApiKeyData, BrokerApiKeyListActionsService, BrokerApiKeyListInputData, BrokerApiKeyQueryParams, MessageService, QueryParams, QueryParamsService, parseNumber } from 'common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WebPaths } from 'projects/partner/src/app/shared/SharedConstants';

@UntilDestroy()
@Component({
  selector: 'ifbp-broker-api-key-list',
  templateUrl: './broker-api-key-list.component.html',
  styleUrls: ['./broker-api-key-list.component.scss'],
  providers: [BrokerApiKeyListFacade]
})
export class BrokerApiKeyListComponent implements OnInit {
  data: BrokerApiKeyListInputData = {
    dataSource: {
      totalCount: 0,
      values: [],
    },
    writeAllowed: true,
    filterData: new QueryParams()
  };
  private brokerId: number;
  private queryParams: BrokerApiKeyQueryParams;

  readonly WebPaths = WebPaths;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly queryParamsService: QueryParamsService,
    private readonly brokerApiKeysFacade: BrokerApiKeysFacade,
    private readonly brokerApiKeyListFacade: BrokerApiKeyListFacade,
    private readonly messageService: MessageService,
    private readonly actionService: BrokerApiKeyListActionsService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.watchActions();
    const brokerIdRaw = this.route.snapshot.paramMap.get("id");
    if(brokerIdRaw)
      this.brokerId = parseNumber(brokerIdRaw);

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = {
        brokerId: this.brokerId,
        ...this.queryParamsService.init(it),
      };

      this.refreshBrokerApiKeys();
    });
  }

  private watchActions() {
    this.actionService.filter$
      .pipe(untilDestroyed(this))
      .subscribe((paginator: MatPaginator) => this.onFilter(paginator));

    this.actionService.delete$
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => this.onDelete(id));

    this.actionService.statusChanged$
      .pipe(untilDestroyed(this))
      .subscribe((row: BrokerApiKeyData) => this.onStatusChanged(row));

    this.actionService.edit$
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => this.onEdit(id));
  }

  private refreshBrokerApiKeys() {
    const queryParams: BrokerApiKeyQueryParams = {
      brokerId: this.brokerId,
      limit: this.queryParams.limit,
      skip: this.queryParams.skip,
      sort: this.queryParams.sort
    };

    this.brokerApiKeysFacade.getBrokerApiKeys(queryParams).subscribe((res)=> {
      this.data = {
        dataSource: res,
        writeAllowed: true,
        filterData: this.queryParams
      };
    });
  }

  onStatusChanged(row: BrokerApiKeyData) {
    this.brokerApiKeysFacade
      .changeBrokerApiKeyStatus(row.id, { ...row, active: !row.active })
      .subscribe({
        next: () => {
          this.refreshBrokerApiKeys();
          this.messageService.success(
            `Broker API key has been successfully ${
              row.active ? "deactivated." : "activated."
            }`
          );
        },
        error: () =>
          this.messageService.error(
            `Broker API key cannot be ${
              row.active ? "deactivated." : "activated."
            }`
          ),
      });
  }

  onDelete(id: number) {
    this.brokerApiKeysFacade.removeBrokerApiKey(id).subscribe({
      next: () => {
        this.refreshBrokerApiKeys();
        this.messageService.success(
          `Broker API key has been successfully removed.`
        );
      },
      error: () =>
        this.messageService.error(`Broker API key cannot be removed.`),
    });
  }

  onFilter(paginator: MatPaginator) {
    this.brokerApiKeyListFacade.filter(paginator);
  }

  private onEdit(id: number) {
    this.router.navigate([`/admin/api-keys/${id}`]);
  }

}
