import { Component, Input } from '@angular/core';
import { OfferRequirementData, OfferRequirementDocumentData, OfferRequirementsIndicatorType } from '../../offer-requirements-section.model';
import { SubmissionDetailsActionsService } from '../../../../../actions/submission-details-actions.service';
import { UploadFileDialogImprovedComponent } from 'projects/partner/src/app/shared/upload-file-dialog-improved/upload-file-dialog-improved.component';
import { MatDialog } from '@angular/material/dialog';
import { UppyFile } from "@uppy/core";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData } from 'projects/partner/src/app/feature-modules/submissions/domain/models/submissions.model';
import { DocumentCategory } from 'common';

@Component({
  selector: 'ifbp-offer-requirement-panel',
  templateUrl: './offer-requirement-panel.component.html',
  styleUrls: ['./offer-requirement-panel.component.scss']
})
export class OfferRequirementPanelComponent {
  @Input()
  requirement: OfferRequirementData;

  readonly IndicatorType = OfferRequirementsIndicatorType;

  constructor(
    private readonly actions: SubmissionDetailsActionsService,
    private readonly dialog: MatDialog
  ) { }

  onDocumentNameClick(document: OfferRequirementDocumentData) {
    if (!document.isDownloadable) {
      return;
    }

    const data: DownloadCheckoutRequirementDocumentData = {
      checkoutRequirementId: this.requirement.requirementId,
      documentId: document.id,
      filename: document.name
    };
    this.actions.downloadCheckoutRequirementDocument(data);
  }

  onRemoveDocumentClick(document: OfferRequirementDocumentData) {
    const data: RemoveCheckoutRequirementDocumentData = {
      checkoutRequirementId: this.requirement.requirementId,
      documentId: document.id,
    };
    this.actions.removeCheckoutRequirementDocument(data);
  }

  onUploadDocumentClick() {
    const dialogRef = UploadFileDialogImprovedComponent.open(this.dialog, { maxFileCount: this.requirement.maxDocumentsCountToUpload ?? 0 });
    dialogRef.afterClosed().subscribe((uploadedFiles?: UppyFile[]) => {
      if (!uploadedFiles || uploadedFiles.length === 0) {
        return;
      }

      const data: SaveCheckoutRequirementDocumentData[] = uploadedFiles?.map((file: UppyFile) => {
        return {
          checkoutRequirementId: this.requirement.requirementId,
          category: <DocumentCategory>this.requirement.category,
          name: file.name,
          type: <string>file.type,
          data: file.data
        };
      })

      this.actions.saveCheckoutRequirementDocuments(data);
    });
  }
}
