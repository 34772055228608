<ng-container *ngIf="rows?.length > 0">
  <ng-container *ngFor="let row of rows; even as isEven; last as isLast; index as index; count as count">
    <div class="row"
         [ngClass]="{'row--narrow': displayMode === DisplayModes.Narrow}"
         *ngIf="isEven">
      <div class="item-wrapper"
           [ngClass]="{'item-wrapper--narrow': displayMode === DisplayModes.Narrow}">
        <div class="item">
          <span class="item__label">{{row.label}}</span>
          <span class="item__value">{{row.value}}</span>
        </div>
        <ng-container *ngTemplateOutlet="divider"></ng-container>
      </div>
      <ng-container *ngIf="(index + 1) <= (count - 1); else emptyWrapper">
        <div class="item-wrapper"
             [ngClass]="{'item-wrapper--narrow': displayMode === DisplayModes.Narrow}">
          <div class="item">
            <span class="item__label">{{rows[index + 1].label}}</span>
            <span class="item__value">{{rows[index + 1].value}}</span>
          </div>
          <ng-container *ngTemplateOutlet="divider"></ng-container>
        </div>
      </ng-container>
      <ng-template #emptyWrapper>
        <div class="item-wrapper"
             [ngClass]="{'item-wrapper--narrow': displayMode === DisplayModes.Narrow}"></div>
      </ng-template>
      <ng-template #divider>
        <mat-divider *ngIf="!isLast && (index + 1) < (count - 1)"></mat-divider>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
