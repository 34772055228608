import { Injectable } from "@angular/core";
import { ApplicationOffer } from "common";
import { filterShareableOfferBundles, getSelectedOfferBundle } from "../../../submission-details.data";
import { ApplicationData } from "../../../../../domain/models/application.model";
import { OfferDetailsData } from "./offer-details-section.model";
import { OfferDetailsPanelAdapter } from "../offer-details-panel/offer-details-panel.data";
import { OfferDetailsPanelInputData } from "../offer-details-panel/offer-details-panel.model";

@Injectable()
export class OfferDetailsSectionAdapter {
  constructor(
    private readonly panelAdapter: OfferDetailsPanelAdapter,
  ) { }

  adaptOfferDetailsSectionData = (app: ApplicationData, isEditable: boolean = false): OfferDetailsData | undefined => {
    const filteredOfferBundles = filterShareableOfferBundles(app.offerBundles);
    const selectedOfferBundle = getSelectedOfferBundle(app);
    const selectedOffer: ApplicationOffer | undefined = selectedOfferBundle?.offers?.at(0);

    if (!filteredOfferBundles?.length || !selectedOffer) {
      return undefined;
    }

    const panelInputData: OfferDetailsPanelInputData = {
      app,
      offer: selectedOffer,
      offerBundleId: <number>selectedOfferBundle?.id,
      isCustomerOfferViewRedirectAvailable: false,
      isSelectable: false,
      isRejectable: false,
      isMarkedAsSelected: isEditable,
      isRejected: false
    }

    const panelData = this.panelAdapter.adaptPanelData(panelInputData);

    return {
      isEditable,
      productCode: selectedOffer.productCode,
      panelData,
      shareActionsInputData: { app, filteredOfferBundles },
    }
  }
}
