import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProcessingDetailsFacade } from './processing-details.facade';
import { ProcessingDetailsAdapter, ProcessingDetailsComponentData } from './processing-details.model';
import { ApplicationDocumentData } from '../../../../domain/models/application.model';
import { SubmissionDetailsActionsService } from '../../actions/submission-details-actions.service';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';
import { SubmissionDetailsParams } from '../../submission-details.model';

@UntilDestroy()
@Component({
  selector: 'ifbp-processing-details',
  templateUrl: './processing-details.component.html',
  styleUrls: ['./processing-details.component.scss'],
  providers: [ProcessingDetailsFacade, ProcessingDetailsAdapter]
})
export class ProcessingDetailsComponent implements OnChanges {
  viewData: ProcessingDetailsComponentData;
  readonly BrokerStatus = BrokerStatus;

  @Input()
  params: SubmissionDetailsParams;

  constructor(
    private readonly facade: ProcessingDetailsFacade,
    private readonly actions: SubmissionDetailsActionsService,
  ) { }

  ngOnChanges(): void {
    this.initData();
  }

  private initData() {
    return this.facade.getComponentData$(this.params.appId).pipe(untilDestroyed(this))
      .subscribe(data => {
        this.viewData = data;
        this.actions.markDataAsLoaded(true);
      });
  }

  onDownloadDocument(data: ApplicationDocumentData) {
    this.actions.downloadApplicationDocument(data);
  }
}
