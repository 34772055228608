import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebPaths } from '../../shared/SharedConstants';
import { AppBarActionsComponent, AppBarCloseAction, AppBarComponent, AppBarTitleComponent, AppPageComponent, AppSidenavComponent } from 'common';
import { AppBarDropdownComponent } from '../../shared/app-bar-dropdown/app-bar-dropdown.component';
import { AppPageResolver, AppSidenavItemsResolver } from '../../app.resolver';
import { appBarData, appBarDropdownData } from '../../app.data';
import { BrokerApiKeyListComponent } from './features/broker-api-key-list/broker-api-key-list.component';
import { BrokerApiKeyDetailsComponent } from './features/broker-api-key-details/broker-api-key-details.component';
import { AdminApiKeysGuard } from './admin-api-keys.guard';

const routes: Routes = [
  {
    path: "",
    component: AppPageComponent,
    data: {
      appBar: appBarData(),
      appBarDropdown: appBarDropdownData(),
    },
    resolve: {
      appPage: AppPageResolver,
      appSidenav: AppSidenavItemsResolver,
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarDropdownComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "sidenav-content",
        component: AppSidenavComponent,
      },
      {
        path: WebPaths.Admin.slice(1),
        title: "Admin – Idea Financial Partner",
        canActivate: [AdminApiKeysGuard],
        component: BrokerApiKeyListComponent
      },
    ]
  },
  {
    path: WebPaths.NewApiKey.slice(1),
    title: "New API key - Idea Broker Portal",
    canActivate: [AdminApiKeysGuard],
    component: AppPageComponent,
    data: {
      appPage: {
          contentClass: "simple",
          contentAlignment: "center-start",
          contentWithoutPadding: true,
          sidenavSubtitle: "",
          sidenavTitle: "",
      },
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        logoHidden: true,
        containerClass: "start-center",
      },
      appBarActions: {
        menuMobile: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: BrokerApiKeyDetailsComponent
      },
    ],
  },
  {
    path: WebPaths.UpdateApiKey.slice(1) + "/:id",
    title: "Update API key - Idea Broker Portal",
    canActivate: [AdminApiKeysGuard],
    component: AppPageComponent,
    data: {
      appPage: {
          contentClass: "simple",
          contentAlignment: "center-start",
          contentWithoutPadding: true,
          sidenavSubtitle: "",
          sidenavTitle: "",
      },
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        logoHidden: true,
        containerClass: "start-center",
      },
      appBarActions: {
        menuMobile: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: BrokerApiKeyDetailsComponent
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
