import { NgModule } from "@angular/core";
import { OfferRequirementsSectionComponent } from "./offer-requirements-section.component";
import { OfferRequirementPanelComponent } from "./components/offer-requirement-panel/offer-requirement-panel.component";
import { SubmissionDetailsSharedComponentsModule } from "../../components/submission-details-shared-components.module";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule,
  ],
  declarations: [
    OfferRequirementsSectionComponent,
    OfferRequirementPanelComponent
  ],
  exports: [
    OfferRequirementsSectionComponent,
  ]
})
export class OfferRequirementsSectionModule { }
