import { NgModule } from "@angular/core";
import { GenerateEmailOfferComponent } from "../offer-share-actions/components/email-offer-details/generate-email-offer.component";
import { SendBankLinkComponent } from "../offer-share-actions/components/send-bank-link-email/send-bank-link.component";
import { SubmissionDetailsSharedComponentsModule } from "../../components/submission-details-shared-components.module";
import { OfferShareActionsComponent } from "./offer-share-actions.component";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule,
  ],
  declarations: [
    GenerateEmailOfferComponent,
    SendBankLinkComponent,
    OfferShareActionsComponent
  ],
  exports: [
    OfferShareActionsComponent
  ]
})
export class OfferShareActionsModule { }
