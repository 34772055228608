import { Component, Input } from '@angular/core';
import { LabelValueData, SubmissionDetailsBasicSectionDisplayMode } from './submission-details-basic-section.model';

@Component({
  selector: 'ifbp-submission-details-basic-section',
  templateUrl: './submission-details-basic-section.component.html',
  styleUrls: ['./submission-details-basic-section.component.scss']
})
export class SubmissionDetailsBasicSectionComponent {
  readonly DisplayModes = SubmissionDetailsBasicSectionDisplayMode

  @Input()
  displayMode: SubmissionDetailsBasicSectionDisplayMode = SubmissionDetailsBasicSectionDisplayMode.Default;

  @Input()
  rows: LabelValueData[];
}
