import { SubmissionDetailsHeaderData } from "../../submission-details.model";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { AppBarAction, ButtonColor, DialogActionData } from "common";
import { ContractDetailsActionType } from './contract-details.model';

export const getContractDetailsHeaderData = (brokerStatus: BrokerStatus): SubmissionDetailsHeaderData | undefined => {
  switch (brokerStatus) {
    case BrokerStatus.CONTRACT_READY:
      return contractReadyDetailsHeaderData;

    case BrokerStatus.CONTRACT_OUT:
      return contractOutDetailsHeaderData;

    default:
      return undefined;
  }
}

const contractReadyDetailsHeaderData: SubmissionDetailsHeaderData = {
  title: "Congratulations it’s time for checkout!",
  subtitle: "Pressing Send Checkout will create the loan and send an activation email to the client with instructions on how to access their funds."
}

const contractOutDetailsHeaderData: SubmissionDetailsHeaderData = {
  title: "Checkout has been sent!",
  subtitle: "Resend an activation email to the client with instructions on how to access their funds."
}

export const getContractDetailsAppBarActions = (brokerStatus: BrokerStatus): AppBarAction[] => {
  switch (brokerStatus) {
    case BrokerStatus.CONTRACT_READY:
      return [
        {
          id: ContractDetailsActionType.SendCheckout,
          label: "SEND CHECKOUT",
          buttonType: "button",
          buttonAppearance: "flat",
          buttonColor: "primary",
        },
      ];

    case BrokerStatus.CONTRACT_OUT:
      return [
        {
          id: ContractDetailsActionType.ResendCheckout,
          label: "RESEND CHECKOUT",
          buttonType: "button",
          buttonAppearance: "flat",
          buttonColor: "primary",
        },
      ];

    default:
      return [];
  }
}

export const loanCreatedDialogData: DialogActionData = {
  body: "The loan has been created and an activation email has been sent to the client.",
  title: "Loan has been created",
  buttons: [
    {
      label: "OK",
      buttonColor: ButtonColor.Primary,
      action: true,
    }
  ]
}
