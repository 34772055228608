import { Injectable } from "@angular/core";
import { EnumValueLabelPipe, OfferTermLabelPipe, PaymentFrequency, PaymentFrequencyLabel, ProductCode } from "common";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import { LocOfferCalculator } from 'projects/partner/src/app/feature-modules/submissions/infrastructure/calculators/loc-offer-calculator';
import { CalculatorService } from 'projects/partner/src/app/feature-modules/submissions/infrastructure/calculators/calculator.service';
import { TermOfferCalculator } from 'projects/partner/src/app/feature-modules/submissions/infrastructure/calculators/term-offer-calculator';
import { OfferCalculator } from 'projects/partner/src/app/feature-modules/submissions/infrastructure/calculators/offer-calculator';
import { LabelValueData } from "../../components/submission-details-basic-section/submission-details-basic-section.model";
import { OfferDetailsPanelData, OfferDetailsPanelHeaderData, OfferDetailsPanelInputData } from "./offer-details-panel.model";
import { SubmissionDetailsBaseAdapter } from "../../../submission-details.data";
import { OfferRequirementsAdapter } from "../offer-requirements-section/offer-requirements-section.data";

@Injectable()
export class OfferDetailsPanelAdapter {
  constructor(
    private readonly enumValueLabelPipe: EnumValueLabelPipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly percentPipe: PercentPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly calculator: CalculatorService,
    private readonly submissionBaseAdapter: SubmissionDetailsBaseAdapter,
    private readonly offerRequirementsAdapter: OfferRequirementsAdapter,
    private readonly offerTermLabelPipe: OfferTermLabelPipe,
  ) { }

  adaptPanelData(inputData: OfferDetailsPanelInputData): OfferDetailsPanelData | undefined {
    switch (inputData?.offer?.productCode) {
      case ProductCode.LOC:
        return this.adaptLocPanelData(inputData);
      case ProductCode.Term:
        return this.adaptTermPanelData(inputData);
      default:
        return undefined;
    }
  }

  adaptLocPanelData(inputData: OfferDetailsPanelInputData): OfferDetailsPanelData {
    const calc = new LocOfferCalculator(inputData.app, inputData.offer, this.percentPipe, this.currencyPipe, this.calculator);

    return {
      ...this.adaptCommonPanelData(inputData, calc),
      detailsPreviewUrl: this.prepareDetailsPreviewUrl(inputData, ProductCode.LOC),
      rows: [
        { label: 'Credit limit', value: this.getFormattedAmount(calc) },
        ...this.adaptCommonRows(calc),
        { label: 'Daily finance charges', value: <string>this.percentPipe.transform(calc?.dailyInterestRate, "1.4-4") },
        { label: 'Monthly finance cost*', value: <string>this.percentPipe.transform(calc?.monthlyRate, "1.2-2") },
        { label: 'Annual finance cost*', value: <string>this.percentPipe.transform(calc?.annualRate, "1.2-2") },
        {
          label: `Commissions (${<string>this.percentPipe.transform(calc?.brokerInitialDrawCommision, "1.2-2")})`,
          value: <string>this.currencyPipe.transform(calc?.getCommision(), 'USD', 'symbol', '1.2-2')
        }
      ],
      footerText: "* The cost of finance is calculated based on the finance charges paid over the life of the loan, assuming an organic repayment.",
    } as OfferDetailsPanelData;
  }

  adaptTermPanelData(inputData: OfferDetailsPanelInputData): OfferDetailsPanelData {
    const calc = new TermOfferCalculator(inputData.app, inputData.offer, this.percentPipe, this.currencyPipe, this.calculator);

    return {
      ...this.adaptCommonPanelData(inputData, calc),
      detailsPreviewUrl: this.prepareDetailsPreviewUrl(inputData, ProductCode.Term),
      rows: [
        { label: 'Loan amount', value: this.getFormattedAmount(calc), },
        ...this.adaptCommonRows(calc),
        { label: 'Factor rate', value: <string>this.decimalPipe.transform(calc?.rate / 100 + 1, "1.4-4") },
        {
          label: `Commissions (${<string>this.percentPipe.transform(<number>calc?.applicationOffer?.maxUpsell / 100, "1.2-2")})`,
          value: <string>this.currencyPipe.transform(calc?.getCommision(), 'USD', 'symbol', '1.2-2')
        }
      ],
    } as OfferDetailsPanelData;
  }

  adaptCommonPanelData(inputData: OfferDetailsPanelInputData, calc: OfferCalculator): Partial<OfferDetailsPanelData> {
    return {
      isRejected: inputData.isRejected,
      isSelectable: inputData.isSelectable,
      isRejectable: inputData.isRejectable,
      isMarkedAsSelected: inputData.isMarkedAsSelected,
      applicationId: inputData.app.id,
      offerBundleId: inputData.offerBundleId,
      headerData: this.prepareHeaderData(calc),
      requirementNames: this.prepareRequirementNames(inputData),
    }
  }

  private adaptCommonRows(calc: OfferCalculator): LabelValueData[] {
    const formattedPaymentFrequency = this.getFormattedPaymentFrequency(calc);
    return [
      { label: 'Term', value: this.getFormattedTerm(calc) },
      { label: `Fee (${calc?.drawFeeFormatted})`, value: <string>this.currencyPipe.transform(calc?.uiFee) },
      { label: 'Disbursed amount', value: <string>this.currencyPipe.transform(calc?.disbursedAmount, 'USD', 'symbol', '1.2-2') },
      { label: `${formattedPaymentFrequency} payment`, value: <string>this.currencyPipe.transform(calc?.termPayment) },
      { label: 'Finance charges paid', value: <string>this.currencyPipe.transform(calc?.sampleDuration?.interest) },
      { label: 'Payback', value: <string>this.currencyPipe.transform(calc?.sampleDuration?.payback) },
    ]
  }

  private prepareHeaderData(calc: OfferCalculator): OfferDetailsPanelHeaderData {
    return {
      amount: calc.amount,
      formattedAmount: this.getFormattedAmount(calc),
      formattedTerm: this.getFormattedTerm(calc),
      formattedPaymentFrequency: `${this.getFormattedPaymentFrequency(calc)} payments`
    }
  }

  private getFormattedAmount(calc: OfferCalculator): string {
    return <string>this.currencyPipe.transform(calc?.amount);
  }

  private getFormattedTerm(calc: OfferCalculator) {
    return this.offerTermLabelPipe.transform(calc?.term);
  }

  private getFormattedPaymentFrequency(calc: OfferCalculator) {
    return this.enumValueLabelPipe.transform(calc?.paymentFrequency, PaymentFrequency, PaymentFrequencyLabel);
  }

  private prepareDetailsPreviewUrl(inputData: OfferDetailsPanelInputData, productCode: ProductCode) {
    if (!inputData.isCustomerOfferViewRedirectAvailable) {
      return undefined;
    }

    return this.submissionBaseAdapter.getDetailsPreviewUrl(<string>inputData.app.uuid, productCode, inputData.offerBundleId)
  }

  private prepareRequirementNames(inputData: OfferDetailsPanelInputData) {
    const filteredRequirements = this.offerRequirementsAdapter.filterCheckoutRequirements(inputData.checkoutRequirements);
    if (!filteredRequirements) {
      return undefined;
    }

    const closingDocumentsNames = filteredRequirements.closingDocumentRequirements.map((item) => item.requiredDocument);
    const nextStepsNames = filteredRequirements.nextStepsRequirements.map((item) => item.requiredDocument);
    return [...closingDocumentsNames, ...nextStepsNames];
  }
}
