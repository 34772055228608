import { Component, Input } from '@angular/core';
import { SubmissionDetailsBannerData } from './submission-details-banner-section.model';

@Component({
  selector: 'ifbp-submission-details-banner-section',
  templateUrl: './submission-details-banner-section.component.html',
  styleUrls: ['./submission-details-banner-section.component.scss']
})
export class SubmissionDetailsBannerSectionComponent {
  @Input()
  data: SubmissionDetailsBannerData;
}
