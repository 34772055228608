<ng-container *ngIf="viewData">
  <article class="container">
    <ifbp-submission-details-header-section class="section-container"
                                            [data]="viewData.headerData">
    </ifbp-submission-details-header-section>
    <ng-container *ngIf="!viewData.selectedOfferBundleId">
      <ifbp-offer-list-section class="section-container"
                               [data]="viewData.offerListData">
      </ifbp-offer-list-section>
    </ng-container>
    <ng-container *ngIf="viewData.selectedOfferBundleId">
      <ifbp-offer-details-section class="section-container"
                                  [data]="viewData.offerDetailsData">
      </ifbp-offer-details-section>
    </ng-container>
    <ifbp-offer-requirements-section class="section-container"
                                     [data]="viewData.offerRequirementsData">
    </ifbp-offer-requirements-section>
    <ifbp-submission-details-footer-section class="section-container"
                                            [notInterestedActionData]="viewData.notInterestedActionData"
                                            [appBarActionsData]="viewData.appBarActions"
                                            (appBarActionInvoke)="onAppBarActionInvoke($event, viewData.application.id)">
    </ifbp-submission-details-footer-section>
  </article>
</ng-container>
