import { Component, Input } from '@angular/core';
import { ProcessingDetailsBasicSectionData } from '../../processing-details.model';

@Component({
  selector: 'ifbp-processing-details-basic-section',
  templateUrl: './processing-details-basic-section.component.html',
  styleUrls: ['./processing-details-basic-section.component.scss']
})
export class ProcessingDetailsBasicSectionComponent {
  @Input()
  data: ProcessingDetailsBasicSectionData;
}
