import { AppSettings } from "../../app.settings";
import { UserDbRole } from "../broker/domain/models/broker.model";
import { ApplicationFileCategory } from "../submission/models/submission.model";

export const ApiUrls = (appSettings: AppSettings) => {
  return {
    SignIn: `${appSettings.oauth.url}/token`,
    PhoneNumberConfirm: `${appSettings.oauth.url}/api/phone-number/confirm`,
    ForgotPassword: `${appSettings.brokers.url}/api/user/password/forgot`,
    GetCurrentUserDetails: `${appSettings.brokers.url}/api/user/current`,
    GetBrokerDetails: ({ user }: { user: { brokerId: number } }) =>
      `${appSettings.brokers.url}/api/broker/${user.brokerId}`,
    GetBrokerUsers: `${appSettings.brokers.url}/api/user`,
    GetBrokerUser: ({ user }: { user: { id: number } }) =>
      `${appSettings.brokers.url}/api/user/${user.id}`,
    CreateBrokerUser: `${appSettings.brokers.url}/api/user/team-invitation`,
    EditBrokerUser: ({ user }: { user: { id: number } }) =>
      `${appSettings.brokers.url}/api/user/${user.id}`,
    DeleteBrokerUser: ({ user }: { user: { id: number } }) =>
      `${appSettings.brokers.url}/api/user/${user.id}`,
    UpdatePassword: ({ user }: { user: { id: number } }) =>
      `${appSettings.brokers.url}/api/user/${user.id}/password`,
    SaveNewAccount: `${appSettings.applications.url}/api/account`,
    SaveExistingAccount: ({ account }: { account: { id: number } }) =>
      `${appSettings.applications.url}/api/account/${account.id}`,
    SaveNewApplication: `${appSettings.applications.url}/api/application`,
    SaveExistingApplication: ({
      application,
    }: {
      application: { id: number };
    }) => `${appSettings.applications.url}/api/application/${application.id}`,
    SaveNewOwner: `${appSettings.applications.url}/api/contact`,
    SaveExistingOwner: ({ owner }: { owner: { id: number } }) =>
      `${appSettings.applications.url}/api/contact/${owner.id}`,
    SaveApplicationFile: ({
      application,
      category,
    }: {
      application: { id: number };
      category: ApplicationFileCategory;
    }) =>
      `${appSettings.applications.url}/api/application/${application.id}/documents/${category}`,
    SubmitNewApplication: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/application/${application.id}`,
    GetApplications: `${appSettings.applications.url}/api/application`,
    GetApplication: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/application/${application.id}`,
    GetAccount: ({ account }: { account: { id: number } }) =>
      `${appSettings.applications.url}/api/account/${account.id}`,
    GetOwners: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/contact?applicationId=${application.id}`,
    GetApplicationFiles: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/application/${application.id}/documents`,
    GetApplicationFile: ({
      application,
      file,
    }: {
      application: { id: number };
      file: { id: number };
    }) =>
      `${appSettings.applications.url}/api/application/${application.id}/documents/${file.id}`,
    GetLostReasons: () =>
      `${appSettings.applications.url}/api/dictionary/lost-reason`,
    GetCompetitors: () =>
      `${appSettings.competitors.url}/api/competitors`,
    GetAccountDetails: ({ loan }: { loan: { id: number } }) =>
      `${appSettings.loans.url}/api/account/${loan.id}`,
    UpdateApplicationStage: ({
      application,
    }: {
      application: { id: number };
    }) => `${appSettings.applications.url}/api/application/${application.id}`,
    SendCheckout: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/application/${application.id}/create-loans`,
    ResendCheckout: ({ application }: { application: { id: number } }) =>
      `${appSettings.applications.url}/api/application/${application.id}/resend-checkout`,
    GetCheckoutRequirementFile: ({
      checkoutRequirement,
      file,
    }: {
      checkoutRequirement: { id: number };
      file: { id: number };
    }) =>
      `${appSettings.applications.url}/api/checkout-requirements/${checkoutRequirement.id}/files/${file.id}`,
    SaveCheckoutRequirementFile: ({
      checkoutRequirement,
      category,
    }: {
      checkoutRequirement: { id: number };
      category: string;
    }) =>
      `${appSettings.applications.url}/api/checkout-requirements/${checkoutRequirement.id}/files/${category}`,
    SubmitOfferBundle: ({ offerBundle }: { offerBundle: { id: number } }) =>
      `${appSettings.applications.url}/api/offer-bundle/${offerBundle.id}/status`,
    SendBankLinkEmail: ({ application }: { application: { id: number } }) =>
      `${appSettings.banking.url}/api/banking/linking-invitation/${application.id}`,
    DeleteApplicationFile: (applicationId: number, fileId: number) => `${appSettings.applications.url}/api/application/${applicationId}/documents/${fileId}/partner`,
    DashboardWidgets: `${appSettings.brokers.url}/api/dashboard`,
    CheckRegistrationToken: (email: string, token: string) => `${appSettings.brokers.url}/api/user/register/check-token?email=${email}&registrationToken=${token}`,
    SignUp: `${appSettings.brokers.url}/api/user/register`,
    ResendInvitation: (brokerUserId: number) => `${appSettings.brokers.url}/api/user/resend-invitation/${brokerUserId}`,
    GetSubmissions: `${appSettings.brokers.url}/api/submissions`,
  };
};

export const WebPaths = {
  Dashboard: "/dashboard",
  SignIn: "/sign-in",
  SignUp: "/sign-up",
  Profile: "/profile",
  Mfa: "/mfa",
  UpdatePassword: "/update-password",
  ResetPassword: "/password/reset",
  ResetPasswordDone: "/reset-done",
  Submissions: "/submissions",
  NewSubmission: "/submissions/new",
  Team: "/team",
  Admin: "/admin",
  NewApiKey: "/admin/api-key/new",
  UpdateApiKey: "/admin/api-keys",
  NewUser: "/user/new",
  EditUser: ({ user }: { user: { id: number } }) => `/user/${user.id}/edit`,
};

const excludedLoginRedirectPaths = ["/password/reset", "/sign-in"];

function stripSlashes(str: string): string {
  return str.replace(/\/\\/i, "");
}

function isPathInPaths(path: string, paths: string[]) {
  path = stripSlashes(path);
  return paths.some(pathStart => {
    return path.startsWith(stripSlashes(pathStart));
  });
}

export function isExcludedLoginRedirectPath(path: string): boolean {
  return isPathInPaths(path, excludedLoginRedirectPaths);
}

export enum UserUiRole {
  ADMIN = "Admin",
  USER = "Team Member",
}

// map user's role from db to UI friendly name
export const userRoleUiMap = new Map<UserDbRole, UserUiRole>([
  [UserDbRole.ADMIN, UserUiRole.ADMIN],
  [UserDbRole.USER, UserUiRole.USER],
]);

export enum AdminSidenavFilters {
  ADMIN = "Admin"
}

export enum TeamSidenavFilters {
  TEAM = "Team",
  ADMINS = "Admins",
  TEAM_MEMBERS = "Team Members",
  DEACTIVATED_USERS = "Deactivated Users",
}

export enum BrokerStatus {
  DRAFT = "draft",
  SUBMISSION_INCOMPLETE = "submission-incomplete",
  PROCESSING = "processing",
  OFFER = "offer",
  CONDITIONAL_OFFER = "conditional-offer",
  CLOSING = "closing",
  CLOSING_INCOMPLETE = "closing-incomplete",
  CONTRACT_READY = "contract-ready",
  CONTRACT_OUT = "contract-out",
  DECLINED = "declined",
  DORMANT = "dormant",
  ABANDONED = "abandoned",
  OPEN = "open",
  FUNDED = "funded",
  CLOSED = "closed",
  NOT_INTERESTED = "not-interested",
}

export const BrokerStatusLabel = new Map<string, string>([
  [BrokerStatus.DRAFT, 'Draft'],
  [BrokerStatus.SUBMISSION_INCOMPLETE, 'Submission incomplete'],
  [BrokerStatus.PROCESSING, 'Processing'],
  [BrokerStatus.OFFER, 'Offer'],
  [BrokerStatus.CONDITIONAL_OFFER, 'Conditional Offer'],
  [BrokerStatus.CLOSING, 'Closing'],
  [BrokerStatus.CLOSING_INCOMPLETE, 'Closing incomplete'],
  [BrokerStatus.CONTRACT_READY, 'Contract Ready'],
  [BrokerStatus.CONTRACT_OUT, 'Contract Out'],
  [BrokerStatus.DECLINED, 'Declined'],
  [BrokerStatus.DORMANT, 'Dormant'],
  [BrokerStatus.ABANDONED, 'Abandoned'],
  [BrokerStatus.OPEN, 'Open'],
  [BrokerStatus.FUNDED, 'Funded'],
  [BrokerStatus.CLOSED, 'Closed'],
  [BrokerStatus.NOT_INTERESTED, 'Not Interested'],
]);

// TODO to remove after finishing refactoring submission details
export enum SubmissionDetailsViews {
  SubmissionForm = "edit",
  ProcessingDetails = "details",
  OfferDetails = "offerdetails",
  ClosingReview = "closingreview",
  ClosedDetails = "closeddetails",
  CheckoutReady = "checkoutready",
  OpenDetails = "opendetails",
  Refactored = "",
}

export const passwordValidatorRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

export const DEFAULT_UPPY_FILE_DIALOG_CLASS = "uppy-dialog";
