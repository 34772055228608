import { SubmissionDetailsBannerData } from "../../shared/components/submission-details-banner-section/submission-details-banner-section.model";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { AppBarAction } from "common";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { OfferDetailsData } from "../../shared/modules/offer-details-section/offer-details-section.model";
import { OfferRequirementsSectionData } from "../../shared/modules/offer-requirements-section/offer-requirements-section.model";

export interface ClosingDetailsComponentData extends SubmissionDetailsBaseComponentData {
  bannerData?: SubmissionDetailsBannerData;
  selectedOfferBundleId?: number;
  offerDetailsData?: OfferDetailsData;
  offerRequirementsData?: OfferRequirementsSectionData;
  appBarActions?: AppBarAction[];
  advisorData: AdvisorData;
}

export enum ClosingDetailsActionType {
  SubmitForReview = "submitForReview",
}
