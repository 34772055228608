import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserDbRole } from "../../broker/domain/models/broker.model";
import { WebPaths } from "../../shared/SharedConstants";
import { UsersBridgeService } from "../users/domain/+state/users-bridge.service";

@Injectable({
  providedIn: "root",
})
export class AdminApiKeysGuard implements CanActivate {
  constructor(private readonly bridgeService: UsersBridgeService, private readonly router: Router) { }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
      return combineLatest([this.bridgeService.getCurrentBroker$(), this.bridgeService.getBrokerDataDetails$()])
        .pipe(
          take(1),
          map(([brokerUser, brokerDetails]) =>{
            if(brokerUser.role === UserDbRole.ADMIN && brokerDetails.apiEnabled){
              return true;
            }
            return this.router.createUrlTree([WebPaths.Dashboard]);
          }
    ))};
}
