  <section class="container">
    <header class="header">
      <h2 class="heading">
        <span class="heading__icon">1</span>
        <span class="heading__text">Select offer</span>
      </h2>
      <ifbp-offer-share-actions [inputData]="data?.shareActionsInputData"></ifbp-offer-share-actions>
    </header>
    <p class="subheading">Review and select an offer. Press the eye icon to hide an offer. Hidden offers will not be visible when shared with the client.</p>
    <div class="header-actions">
      <button class="header-actions header-actions__item"
              mat-button
              (click)="onClickExpandAll()">EXPAND ALL</button>
      <button class="header-actions header-actions__item"
              mat-button
              (click)="onClickCollapseAll()">COLLAPSE ALL</button>
    </div>

    <div class="offers-container">
      <p class="not-available"
         *ngIf="!isOffersDataAvailable">
        No offers available
      </p>
      <section class="offers"
               *ngIf="isLocOfferPanelsDataAvailable">
        <ifbp-product-name-section-header [productCode]="ProductCode.LOC"
                                          type="secondary">
        </ifbp-product-name-section-header>
        <mat-accordion multi
                       #locAccordion="matAccordion">
          <ifbp-offer-details-panel class="offer"
                                    *ngFor="let data of data.offerPanelsData?.locOffers; trackBy: trackByFn"
                                    [data]="data">
          </ifbp-offer-details-panel>
        </mat-accordion>
      </section>
      <section class="offers"
               *ngIf="isTermOfferPanelsDataAvailable">
        <ifbp-product-name-section-header [productCode]="ProductCode.Term"
                                          type="secondary">
        </ifbp-product-name-section-header>
        <mat-accordion multi
                       #termAccordion="matAccordion">
          <ifbp-offer-details-panel class="offer"
                                    *ngFor="let data of data.offerPanelsData?.termOffers; trackBy: trackByFn"
                                    [data]="data">
          </ifbp-offer-details-panel>
        </mat-accordion>
      </section>
    </div>
  </section>
