import { NgModule } from "@angular/core";
import { NotInterestedActionModule } from "../not-interested-action/not-interested-action.module";
import { NotInterestedDialogModule } from "../not-interested-dialog/not-interested-dialog.module";
import { ProcessingDetailsModule } from "./features/processing-details/processing-details.module";
import { SubmissionDetailsComponent } from './submission-details.component';
import { SubmissionDetailsRoutingModule } from "./submission-details-routing.module";
import { ActivatedDetailsModule } from "./features/activated-details/activated-details.module";
import { SubmissionDetailsSharedModule } from "./shared/submission-details-shared.module";
import { ContractDetailsModule } from "./features/contract-details/contract-details.module";
import { ClosingDetailsModule } from "./features/closing-details/closing-details.module";
import { OfferDetailsModule } from "./features/offer-details/offer-details.module";
import { SubmissionDetailsBaseAdapter } from "./submission-details.data";
import { OfferRequirementsAdapter } from "./shared/modules/offer-requirements-section/offer-requirements-section.data";
import { OfferDetailsSectionAdapter } from "./shared/modules/offer-details-section/offer-details-section.data";
import { OfferDetailsPanelAdapter } from "./shared/modules/offer-details-panel/offer-details-panel.data";

@NgModule({
  imports: [
    SubmissionDetailsSharedModule,
    SubmissionDetailsRoutingModule,
    NotInterestedActionModule,
    NotInterestedDialogModule,
    ProcessingDetailsModule,
    ActivatedDetailsModule,
    ContractDetailsModule,
    ClosingDetailsModule,
    OfferDetailsModule
  ],
  exports: [
    NotInterestedActionModule,
    NotInterestedDialogModule,
  ],
  declarations: [
    SubmissionDetailsComponent,
  ],
  providers: [
    SubmissionDetailsBaseAdapter,
    OfferRequirementsAdapter,
    OfferDetailsSectionAdapter,
    OfferDetailsPanelAdapter
  ]
})
export class SubmissionsDetailsModule { }
