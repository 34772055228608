import { Injectable } from "@angular/core";
import { SubmissionsFacade } from "../../../../domain/+state/submissions.facade";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubmissionDetailsFacade } from "../../submission-details.facade";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { ActivatedDetailsComponentData } from "./activated-details.model";
import { getActivatedDetailsHeaderData } from "./activated-details.data";
import { TransactionStatus, TransactionStatusLabel } from "common";
import { AdvisorData } from "../../../../shared/models/advisor.model";

@Injectable()
export class ActivatedDetailsFacade {
  constructor(
    private readonly detailsFacade: SubmissionDetailsFacade,
    private readonly submissionsFacade: SubmissionsFacade
  ) { }

  getComponentData$(applicationId: number): Observable<ActivatedDetailsComponentData> {
    return this.detailsFacade.getComponentBaseData$(applicationId)
      .pipe(switchMap((data: SubmissionDetailsBaseComponentData) => {
        return this.getActivatedDetailsComponentData$(data);
      }));
  }

  private getActivatedDetailsComponentData$(data: SubmissionDetailsBaseComponentData): Observable<ActivatedDetailsComponentData> {
    return this.submissionsFacade.getAdvisorData$(data.application.id)
      .pipe(switchMap((advisorData: AdvisorData) => {
        const transactionStatusLabel = TransactionStatusLabel;
        transactionStatusLabel.set(TransactionStatus.OnHold, "Pending");

        if (!data.application?.loanId) {
          return of({
            ...data,
            transactionStatusLabel,
            headerData: getActivatedDetailsHeaderData(data.application.brokerStatus, false, advisorData?.phone),
          });
        }
        return this.submissionsFacade.getLoanData(data.application.loanId).pipe(map((loan) => ({
          ...data,
          loan,
          transactionStatusLabel,
          headerData: getActivatedDetailsHeaderData(data.application.brokerStatus, !!loan.initialDraw, advisorData?.phone),
        })))
      }));
  }

}
