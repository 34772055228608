import { NgModule } from "@angular/core";
import { SubmissionDetailsSharedModule } from "../../shared/submission-details-shared.module";
import { OfferDetailsComponent } from "./offer-details.component";
import { OfferListSectionComponent } from './components/offer-list-section/offer-list-section.component';

@NgModule({
  imports: [
    SubmissionDetailsSharedModule,
  ],
  declarations: [
    OfferDetailsComponent,
    OfferListSectionComponent
  ],
  exports: [OfferDetailsComponent]
})
export class OfferDetailsModule { }
