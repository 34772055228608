import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { FiltersChipListModule } from "./filters-chip-list/filters-chip-list.module";
import { NoResultsFoundComponent } from './no-results-found/no-results-found.component';
import { PreventDoubleClicksDirective } from "./directives/prevent-double-clicks.directive";

@NgModule({
  declarations: [NoResultsFoundComponent, PreventDoubleClicksDirective],
  imports: [SharedModule, FiltersChipListModule],
  exports: [SharedModule, FiltersChipListModule, NoResultsFoundComponent, PreventDoubleClicksDirective],
})
export class SubmissionsSharedModule { }
