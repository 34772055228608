import { NgModule } from "@angular/core";
import { SubmissionDetailsSharedComponentsModule } from "../../components/submission-details-shared-components.module";
import { OfferDetailsPanelComponent } from "./offer-details-panel.component";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule
  ],
  declarations: [
    OfferDetailsPanelComponent,
  ],
  exports: [
    OfferDetailsPanelComponent
  ]
})
export class OfferDetailsPanelModule { }
