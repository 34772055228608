import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AppPageService,
  BrokerApiKeyEditData,
  BrokerApiKeyInputData,
  BrokerApiKeyListActionsService,
  CreateBrokerApiKeySuccessData,
  MessageService,
  parseNumber,
} from "common";
import { BrokerApiKeysFacade } from "../../domain/+state/broker-api-keys-facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BrokerApiKeyDetailsFacade } from "./broker-api-key-details.facade";

@UntilDestroy()
@Component({
  selector: "ifbp-broker-api-key-details",
  templateUrl: "./broker-api-key-details.component.html",
  styleUrls: ['./broker-api-key-details.component.scss'],
  providers: [BrokerApiKeyDetailsFacade]
})
export class BrokerApiKeyDetailsComponent implements OnInit {
  data: BrokerApiKeyInputData;

  constructor(
    private readonly actionService: BrokerApiKeyListActionsService,
    private readonly brokerApiKeysFacade: BrokerApiKeysFacade,
    private readonly brokerApiKeyDetailsFacade: BrokerApiKeyDetailsFacade,
    private readonly messageService: MessageService,
    private readonly appPageService: AppPageService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.watchActions();
    this.initRouteParams();
  }

  private watchActions() {
    this.actionService.save$.pipe(untilDestroyed(this)).subscribe((data: BrokerApiKeyEditData) => this.onSave(data));
    this.actionService.delete$.pipe(untilDestroyed(this)).subscribe((id: number) => this.onDelete(id));
  }

  private initRouteParams() {
    const brokerApiKeyIdRaw = this.route.snapshot.paramMap.get("id");
    if (!brokerApiKeyIdRaw || brokerApiKeyIdRaw === "new") {
      this.data = {
        dataSource: undefined,
        writeAllowed: true,
        isCreating: true
      };
      return;
    }

    const brokerApiKeyId = parseNumber(brokerApiKeyIdRaw);
    this.brokerApiKeysFacade
      .getBrokerApiKeyById(brokerApiKeyId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.data = {
          dataSource: res,
          writeAllowed: true,
          isCreating: false,
        };
      });
  }

  private onSave(data: BrokerApiKeyEditData) {
    if (this.data.isCreating) {
      this.brokerApiKeysFacade
        .createBrokerApiKey(this.brokerApiKeyDetailsFacade.prepareCreateRequest(data))
        .pipe(untilDestroyed(this))
        .subscribe({next: (next) => this.handleSuccessCreateBrokerApiKey(next), error: () => this.handleErrorCreateBrokerApiKey()});
      return;
    }

    this.brokerApiKeysFacade
      .updateBrokerApiKey(data.id, { ...data })
      .pipe(untilDestroyed(this))
      .subscribe({next: () => this.handleSuccessUpdateBrokerApiKey(), error: () => this.handleErrorUpdateBrokerApiKey()});
  }

  private onDelete(id: number) {
    this.brokerApiKeysFacade.removeBrokerApiKey(id).subscribe({
      next: () => {
        this.messageService.success(`Broker API key has been successfully removed.`);
        this.appPageService.back();
      },
      error: () => this.messageService.error(`Broker API key cannot be removed.`),
    });
  }

  private handleSuccessCreateBrokerApiKey(data: CreateBrokerApiKeySuccessData) {
    this.messageService.success(
      "Broker API Key has been successfully created."
    );
    this.actionService.markCreateAsCompleted(data);
  }

  private handleErrorCreateBrokerApiKey() {
    this.messageService.error("Broker API Key cannot be created.");
  }

  private handleSuccessUpdateBrokerApiKey() {
    this.messageService.success(
      "Broker API Key has been successfully updated."
    );
    this.appPageService.back();
  }

  private handleErrorUpdateBrokerApiKey() {
    this.messageService.error("Broker API Key cannot be updated.");
  }
}
