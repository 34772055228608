import { NgModule } from "@angular/core";
import { SubmissionDetailsSharedModule } from "../../shared/submission-details-shared.module";
import { ClosingDetailsComponent } from './closing-details.component';

@NgModule({
  imports: [
    SubmissionDetailsSharedModule,
  ],
  declarations: [
    ClosingDetailsComponent,
  ],
  exports: [ClosingDetailsComponent]
})
export class ClosingDetailsModule { }
