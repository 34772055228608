import { Component, Input, OnChanges } from '@angular/core';
import { SubmissionDetailsParams } from '../../submission-details.model';
import { SubmissionDetailsActionsService } from '../../actions/submission-details-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData } from '../../../../domain/models/submissions.model';
import { AppBarAction, AppPageService } from 'common';
import { finalize, tap } from 'rxjs/operators';
import { OfferDetailsFacade } from './offer-details.facade';
import { OfferDetailsActionType, OfferDetailsComponentData } from './offer-details.model';
import { OfferSubmissionAdapter } from './offer-details.data';

@UntilDestroy()
@Component({
  selector: 'ifbp-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
  providers: [OfferDetailsFacade, OfferSubmissionAdapter]
})
export class OfferDetailsComponent implements OnChanges {
  viewData: OfferDetailsComponentData;
  isFirstInit: boolean = true;
  actionInProgress: boolean;

  @Input()
  params: SubmissionDetailsParams;

  constructor(
    private readonly facade: OfferDetailsFacade,
    private readonly actions: SubmissionDetailsActionsService,
    private readonly appPageService: AppPageService
  ) { }

  ngOnChanges(): void {
    this.initData();
    this.initActions();
  }

  private initData() {
    return this.facade.getComponentData$(this.params.appId).pipe(untilDestroyed(this))
      .subscribe(data => {
        this.viewData = data;
        this.actions.markClosingDocumentsSectionAsInvalid(false);
        if (this.isFirstInit) {
          this.firstInitActions(data);
          this.isFirstInit = false;
        }
      });
  }

  private firstInitActions(data: OfferDetailsComponentData) {
    this.actions.markDataAsLoaded(true);
  }

  private initActions() {
    this.actions.removeCheckoutRequirementDocument$.pipe(untilDestroyed(this))
      .subscribe((data: RemoveCheckoutRequirementDocumentData) => {
        this.facade.removeCheckoutRequirementDocument(<number>this.viewData.selectedOfferBundleId, data);
      })

    this.actions.downloadCheckoutRequirementDocument$.pipe(untilDestroyed(this))
      .subscribe((data: DownloadCheckoutRequirementDocumentData) => {
        this.facade.downloadCheckoutRequirementDocument(data);
      })

    this.actions.saveCheckoutRequirementDocuments$.pipe(untilDestroyed(this))
      .subscribe((data: SaveCheckoutRequirementDocumentData[]) => {
        this.facade.saveCheckoutRequirementDocuments(<number>this.viewData.selectedOfferBundleId, data)
      })

    this.actions.updateOfferBundleStatus$.pipe(untilDestroyed(this))
      .subscribe((data) => this.facade.updateOfferBundleStatus(data, this.viewData));
  }

  onAppBarActionInvoke(action: AppBarAction, appId: number): void {
    if (this.actionInProgress) {
      return;
    }
    if (action.id !== OfferDetailsActionType.SubmitForReview) {
      return;
    }

    this.submitForReview(appId);
  }

  private submitForReview(appId: number) {
    if (!this.viewData.selectedOfferBundleId) {
      return;
    }

    if (!this.viewData.offerRequirementsData?.isRequirementsDataValid) {
      this.actions.markClosingDocumentsSectionAsInvalid(true);
      return;
    }

    this.actionInProgress = true;
    this.facade.submitForReview(appId, this.viewData.application.brokerStatus, this.viewData.advisorData)
      .pipe(
        tap(() => this.handleSuccess()),
        finalize(() => this.handleComplete()))
      .subscribe();
  }

  private handleSuccess() {
    this.appPageService.back();
  }

  private handleComplete() {
    this.actionInProgress = false;
  }
}
