import { NgModule } from "@angular/core";
import { ProcessingDetailsComponent } from "./processing-details.component";
import { ProcessingDetailsBasicSectionComponent } from "./components/processing-details-basic-section/processing-details-basic-section.component";
import { ProcessingDetailsDocumentsSectionComponent } from "./components/processing-details-documents-section/processing-details-documents-section.component";
import { SubmissionDetailsSharedModule } from "../../shared/submission-details-shared.module";

@NgModule({
  imports: [SubmissionDetailsSharedModule],
  declarations: [
    ProcessingDetailsComponent,
    ProcessingDetailsBasicSectionComponent,
    ProcessingDetailsDocumentsSectionComponent,
  ],
  exports: [ProcessingDetailsComponent]
})
export class ProcessingDetailsModule { }
