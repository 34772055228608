import { StringHelper } from "common";
import { SubmissionDetailsHeaderData } from "../../submission-details.model";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";

export const getActivatedDetailsHeaderData = (brokerStatus: BrokerStatus, isInitialDrawAvailable: boolean, advisorPhone?: string): SubmissionDetailsHeaderData | undefined => {
  switch (brokerStatus) {
    case BrokerStatus.OPEN:
      return getOpenSubmissionDetailsHeaderData(isInitialDrawAvailable);

    case BrokerStatus.FUNDED:
      return fundedSubmissionDetailsHeaderData;

    case BrokerStatus.CLOSED:
      return closedSubmissionDetailsHeaderData;

    case BrokerStatus.ABANDONED:
      return getAbandonedSubmissionDetailsHeaderData(advisorPhone);

    default:
      return undefined;
  }
}

const getOpenSubmissionDetailsHeaderData = (isInitialDrawAvailable: boolean): SubmissionDetailsHeaderData => {
  const title = "The agreement has been signed";

  if (isInitialDrawAvailable) {
    return {
      ...fundedSubmissionDetailsHeaderData,
      title
    };
  }

  return {
    title,
    subtitle: "Funds are available to draw."
  }
}

const fundedSubmissionDetailsHeaderData: SubmissionDetailsHeaderData = {
  title: "Loan has been activated",
  subtitle: "Initial draw details below."
}

const closedSubmissionDetailsHeaderData: SubmissionDetailsHeaderData = {
  title: "Loan is closed",
  subtitle: "This loan has been paid off. See the initial disbursed amount below."
}

const getAbandonedSubmissionDetailsHeaderData = (advisorPhone?: string): SubmissionDetailsHeaderData => {
  const advisorPhoneFormat = `<a href="tel:${advisorPhone}">${StringHelper.phoneFormat(<string>advisorPhone)}</a>`;

  return {
    title: "Loan is closed",
    subtitle: `This loan was closed due to inactivity. Reach out to your business advisor at ${advisorPhoneFormat} if you have any questions.`
  }
}
