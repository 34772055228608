import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubmissionDetailsViews } from "../../../../shared/SharedConstants";
import { parseNumber } from 'common';
import { SubmissionDetailsService } from "../../domain/+state/submission-details.service";
import { SubmissionsFacade } from "../../domain/+state/submissions.facade";

@Injectable({
  providedIn: "root",
})
export class SubmissionDetailsGuard implements CanActivate {
  constructor(
    private readonly submissionDetailsService: SubmissionDetailsService,
    private readonly submissionsFacade: SubmissionsFacade,) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const detailsViewString: string = route.url?.[2]?.path;
    const hasExistingDetailsView: boolean = (<any>Object).values(SubmissionDetailsViews).includes(detailsViewString);
    const applicationId = parseNumber(route.params?.["id"]);
    if (!applicationId || (detailsViewString && !hasExistingDetailsView)) {
      console.error(`Incorrect data to show submission details view, id: ${applicationId}, details view name: ${detailsViewString}`);
      return false;
    }

    return this.submissionsFacade.getApplicationData(applicationId).pipe(
      map(data => {
        if (this.submissionDetailsService.isDetailsViewCorrectForBrokerStatus(data.brokerStatus, <SubmissionDetailsViews>detailsViewString)) {
          return true
        }
        this.submissionDetailsService.goToSubmissionDetails(<number>data.id, data.brokerStatus);
        return false;
      }),
    );
  }
}
