<mat-dialog-content>
  <h2 class="heading">Generate email offer</h2>
  <p class="description">{{data.accountName}} has been approved for funding from {{data.companyName}}. Generate an email offer to the address below. To add more
    emails, separate each email address with a semicolon.</p>
  <div class="offers"
       *ngIf="locOffers?.length > 0">
    <span class="offers__heading">Line of Credit</span>
    <span class="offers__item"
          *ngFor="let offer of locOffers">
      <ng-container *ngTemplateOutlet="offerPreview; context: { offer }"></ng-container>
    </span>
  </div>
  <div class="offers"
       *ngIf="termOffers?.length > 0">
    <span class="offers__heading">Term loan</span>
    <span class="offers__item"
          *ngFor="let offer of termOffers">
      <ng-container *ngTemplateOutlet="offerPreview; context: { offer }"></ng-container>
    </span>
  </div>
  <ng-template #offerPreview let-offer="offer">
    {{offer.amount | currency:'USD':'symbol':'1.2-2'}} - {{offer.repaymentTerm | offerTermLabel}} -
    {{offer.paymentFrequency | enumValueLabel : PaymentFrequency : PaymentFrequencyLabel}}
  </ng-template>
  <form [formGroup]="form">
    <mat-form-field class="form-field"
                    appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput
             formControlName="email"
             focus="true"
             type="text">
    </mat-form-field>
  </form>
</mat-dialog-content>
<div class="actions">
  <button mat-button
          [mat-dialog-close]="false">CANCEL</button>
  <a [href]="mailTo"
     mat-button
     [mat-dialog-close]="true"
     color="primary">GENERATE EMAIL</a>
</div>
