<section class="section">
  <header>
    <h2 class="heading">Documents and cash flow</h2>
  </header>
  <div class="details">
    <ng-container *ngIf="bankStatementDocuments?.length > 0">
      <div class="row">
        <span class="row__label">Last 3 months bank statement</span>
        <span *ngFor="let bankStatementDocument of bankStatementDocuments"
              class="row__filename"
              (click)="onFileNameClick(bankStatementDocument)">
          {{ bankStatementDocument.name }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="signedApplicationDocuments?.length > 0">
      <mat-divider *ngIf="bankStatementDocuments?.length > 0"></mat-divider>
      <div class="row">
        <span class="row__label">Signed application</span>
        <span *ngFor="let signedApplicationDocument of signedApplicationDocuments"
              class="row__filename"
              (click)="onFileNameClick(signedApplicationDocument)">
          {{ signedApplicationDocument.name }}
        </span>
      </div>
    </ng-container>
  </div>
</section>
