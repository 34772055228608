import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { AppBarAction } from "common";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { OfferDetailsData } from "../../shared/modules/offer-details-section/offer-details-section.model";
import { OfferRequirementsSectionData } from "../../shared/modules/offer-requirements-section/offer-requirements-section.model";
import { OfferShareActionsInputData } from "../../shared/modules/offer-share-actions/offer-share-actions.model";
import { OfferDetailsPanelData } from "../../shared/modules/offer-details-panel/offer-details-panel.model";

export interface OfferDetailsComponentData extends SubmissionDetailsBaseComponentData {
  selectedOfferBundleId?: number;
  offerListData?: OfferListData;
  offerDetailsData?: OfferDetailsData;
  offerRequirementsData?: OfferRequirementsSectionData;
  appBarActions?: AppBarAction[];
  advisorData: AdvisorData;
}

export enum OfferDetailsActionType {
  SubmitForReview = "submitForReview",
}

export interface OfferListData {
  shareActionsInputData?: OfferShareActionsInputData;
  offerPanelsData?: OfferPanelsData
}

export interface OfferPanelsData {
  locOffers: OfferDetailsPanelData[];
  termOffers: OfferDetailsPanelData[];
}
