import { Component, Input, OnChanges } from '@angular/core';
import { ProductCode } from 'common';
import { LoanData } from 'projects/partner/src/app/feature-modules/submissions/domain/models/loan.model';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';

@Component({
  selector: 'ifbp-activated-details-loan-section',
  templateUrl: './activated-details-loan-section.component.html',
  styleUrls: ['./activated-details-loan-section.component.scss']
})
export class ActivatedDetailsLoanSectionComponent implements OnChanges {
  @Input()
  loan?: LoanData;

  @Input()
  brokerStatus: BrokerStatus;

  repaymentTermValue?: string;
  readonly ProductCode = ProductCode;
  readonly BrokerStatus = BrokerStatus;

  ngOnChanges(): void {
    const repaymentTerm = this.loan?.offer?.repaymentTerm;
    const months = <number>repaymentTerm > 1 ? "months" : "month";
    this.repaymentTermValue = repaymentTerm ? `${repaymentTerm} ${months}` : undefined;
  }
}
