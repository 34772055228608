// based on application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/calculator/loc-offer-calculator.ts
import { OfferCalculator } from "./offer-calculator";

export class LocOfferCalculator extends OfferCalculator {
  protected getAnnualRate(): number {
    return this.monthlyRate * 12;
  }

  protected getMonthlyRate(): number {
    const simpleInterestRate =
      this._calculatorService.getLineOfCreditTotalInterest(
        this.applicationOffer,
        this.amount,
        this.term,
      ) / this.amount;
    return simpleInterestRate / this.term;
  }

  protected getTotalInterest(term: number): number {
    return this._calculatorService.getLineOfCreditTotalInterest(
      this.applicationOffer,
      this.sampleDraw,
      term,
    );
  }

  protected getTermPayment(): number {
    return this._calculatorService.getLineOfCreditPayment(
      this.sampleDraw,
      this.rate / 100,
      this.numberOfPaymentsPerAnnum,
      this.totalNumberOfPayments,
    );
  }

  protected initSampleDraw(): void {
    this._sampleDraw = this.amount;
  }

  getCommision(): number {
    return this.amount * this.brokerInitialDrawCommision;
  }

  override get disbursedAmount(): number {
    return this.sampleDraw - this.drawFee - this.originationFeeAmount;
}
}
