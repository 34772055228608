import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MessageService } from "common";
import * as Sentry from "@sentry/angular-ivy";
import {
  AccountData,
  BrokerData,
  MfaTokenPayload,
  RegistrationTokenStatusData,
  ResetPasswordRequest,
  ResetPasswordTokenStatusData,
  SignInMfaRequest,
  SignInRequest,
  SignInResponse,
  SingUpRequest
} from "../domain/model/account.model";
import { ApiPaths } from "../../../core/const/api-paths";
import { adaptBrokerData, BrokerResponse } from "./account-api.model";

@Injectable({
  providedIn: "root",
})
export class AccountRemote {
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  getCurrentAccount(): Observable<AccountData> {
    return this.http.get<AccountData>(ApiPaths.account.userCurrent);
  }

  getBroker(brokerId: number): Observable<BrokerData> {
    const url = ApiPaths.account.broker(brokerId);
    return this.http.get<BrokerResponse>(url).pipe(map(response => adaptBrokerData(response)));
  }

  getRegistrationTokenStatus(email: string, token: string): Observable<any> {
    const errorMessage = "Something went wrong while retrieving registration token status data, please try again later";

    return this.http.get<RegistrationTokenStatusData>(ApiPaths.account.userRegisterCheckToken(email, token)).pipe(catchError(() => {
      this.messageService.error(errorMessage);
      Sentry.captureException(errorMessage);
      return of(null);
    }));
  }

  signUp(request: SingUpRequest): Observable<any> {
    const errorMessage = "Something went wrong while registering user, please try again later";

    return this.http.post(ApiPaths.account.userRegister, request).pipe(catchError((err, caught) => {
      this.messageService.error(errorMessage);
      Sentry.captureException(errorMessage);
      throw err;
    }));
  }

  forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(ApiPaths.account.passwordForgot, {
      email,
    });
  }

  getResetPasswordTokenStatus(email: string, token: string): Observable<any> {
    const errorMessage = "Your password reset link has expired. Please request a new one.";

    return this.http.get<ResetPasswordTokenStatusData>(ApiPaths.account.userPasswordCheckToken(email, token)).pipe(catchError(() => {
      this.messageService.error(errorMessage);
      Sentry.captureException(errorMessage);
      return of(null);
    }));
  }

  signIn(request: SignInRequest): Observable<SignInResponse> {
    const body = this.transformRequest(request);
    return this.http.post<SignInResponse>(ApiPaths.account.signIn, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      withCredentials: true,
    });
  }
  private transformRequest(obj: SignInRequest) {
    const str = [];
    for (const p in obj)
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    return str.join("&");
  }

  confirmPhoneAndSignIn(
    request: SignInMfaRequest,
  ): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(
      ApiPaths.account.phoneNumberConfirm,
      null,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Ryno-Mfa-Token": request.mfa_token,
          "X-Ryno-Mfa-Code": request.mfa_code,
        },
      },
    );
  }

  resetPassword(
    request: ResetPasswordRequest,
    token?: MfaTokenPayload,
  ): Observable<string> {
    let headers = {};
    if (token?.mfaToken) {
      headers = { ...headers, "X-Ryno-Mfa-Token": token?.mfaToken };
    }
    if (token?.mfaCode) {
      headers = { ...headers, "X-Ryno-Mfa-Code": token.mfaCode };
    }
    return this.http.put<string>(ApiPaths.account.userPasswordReset, request, { headers });
  }
}

