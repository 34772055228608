<section class="section">
  <header>
    <h2 class="heading">{{data.title}}</h2>
  </header>
  <ng-container *ngFor="let subsection of data.subsections">
    <div class="subsection"
         [ngClass]="data.subsections?.length > 1 ? 'subsection--multiple' : 'subsection--single'">
      <p *ngIf="subsection?.title"
         class="subsection__heading">{{subsection.title}}</p>
      <ifbp-submission-details-basic-section [rows]="subsection.rows"></ifbp-submission-details-basic-section>
    </div>
  </ng-container>
</section>
