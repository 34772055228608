import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  AppLoaderInterceptor,
  CacheInterceptor,
  ErrorsModule,
  MaterialModule,
  OAuthInterceptor,
  CommonModule,
  ConvertParamsToUrlInterceptor,
  RefreshTokenInterceptor,
} from "common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PartnerCoreModule } from "./core/core.module";
import { BrokerModule } from "./broker/broker.module";
import { UserModule } from "./user/user.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatChipsModule } from "@angular/material/chips";
import { MatListModule } from "@angular/material/list";
import { ProfileModule } from "./profile/profile.module";
import { UpdatePasswordModule } from "./update-password/update-password.module";
import { SubmissionModule } from "./submission/submission.module";
import { BrowserModule } from "@angular/platform-browser";
import { SubmissionsModule } from "./feature-modules/submissions/submissions.module";
import { UsersModule } from "./feature-modules/users/users.module";
import { DashboardModule } from "./feature-modules/dashboard/dashboard.module";
import { AccountModule } from "./feature-modules/account/account.module";
import { AboutPageModule } from "./about/about-page.module";
import { AdminModule } from "./feature-modules/admin/admin.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AboutPageModule,
    BrokerModule,
    CommonModule,
    FlexLayoutModule,
    PartnerCoreModule,
    UserModule,
    DashboardModule,
    UsersModule,
    ProfileModule,
    MaterialModule,
    MatChipsModule,
    MatListModule,
    SubmissionModule,
    UpdatePasswordModule,
    SubmissionsModule,
    AccountModule,
    AdminModule,
    ErrorsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertParamsToUrlInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
