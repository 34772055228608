import { SafeHtml } from "@angular/platform-browser";
import { DocumentCategory, OfferBundleCheckoutRequirement } from "common";

export interface AdaptOfferRequirementsSectionInputData {
  showEmpty: boolean;
  isReadonly?: boolean;
  checkoutRequirements?: OfferBundleCheckoutRequirement[];
  customDescription?: string | SafeHtml;
}

export interface OfferRequirementsSectionData {
  showEmpty: boolean;
  isRequirementsDataValid?: boolean;
  isReadonly?: boolean;
  requirements?: OfferRequirementsData;
  customDescription?: string | SafeHtml;
}

export interface FilteredOfferBundleCheckoutRequirementsData {
  closingDocumentRequirements: OfferBundleCheckoutRequirement[];
  nextStepsRequirements: OfferBundleCheckoutRequirement[];
}

export interface OfferRequirementsData {
  closingDocuments: OfferRequirementData[];
  nextSteps: OfferRequirementData[];
}

export interface OfferRequirementData {
  requirementId: number;
  indicatorType: OfferRequirementsIndicatorType;
  name: string;
  documents?: OfferRequirementDocumentData[];
  description?: string;
  isUploadActionAvailable?: boolean;
  maxDocumentsCountToUpload?: number;
  category?: DocumentCategory;
  isValid: boolean;
}

export interface OfferRequirementDocumentData {
  id: number;
  name: string;
  isRemovable: boolean;
  isDownloadable: boolean;
  isLabelVisible: boolean;
}

export enum OfferRequirementsIndicatorType {
  Met = "met",
  InProgress = "inProgress",
  Incomplete = "incomplete"
}
