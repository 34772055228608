import { Component, Input } from '@angular/core';
import { OfferDetailsData } from './offer-details-section.model';
import { SubmissionDetailsActionsService } from '../../../actions/submission-details-actions.service';
import { UpdateOfferBundleStatusData } from '../../../../../domain/models/submissions.model';
import { OfferBundleStatus } from 'common';

@Component({
  selector: 'ifbp-offer-details-section',
  templateUrl: './offer-details-section.component.html',
  styleUrls: ['./offer-details-section.component.scss']
})
export class OfferDetailsSectionComponent {
  @Input()
  data: OfferDetailsData;

  constructor(private readonly actions: SubmissionDetailsActionsService) { }

  onClickUpdateOffer(data: OfferDetailsData) {
    if (!data.isEditable || !data.panelData) {
      return;
    }

    const updateData: UpdateOfferBundleStatusData = {
      applicationId: data.panelData.applicationId,
      offerBundleId: data.panelData.offerBundleId,
      status: OfferBundleStatus.Pending
    }
    this.actions.updateOfferBundleStatus(updateData);
  }
}
