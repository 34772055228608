import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[preventDoubleClicks]'
})
export class PreventDoubleClicksDirective implements OnInit {
  @Input()
  throttleTime = 500;

  @Output()
  singleClick = new EventEmitter();

  private readonly clicks = new Subject();

  ngOnInit() {
    this.clicks.pipe(
      throttleTime(this.throttleTime),
      untilDestroyed(this)
    ).subscribe(e => this.singleClick.emit(e));
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
