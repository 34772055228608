<ng-container *ngIf="viewData">
  <article class="container">
    <ifbp-submission-details-header-section class="section-container"
                                            [data]="viewData.headerData">
    </ifbp-submission-details-header-section>
    <ifbp-submission-details-banner-section class="section-container"
                                            *ngIf="viewData.bannerData"
                                            [data]="viewData.bannerData">
    </ifbp-submission-details-banner-section>
    <ifbp-offer-details-section class="section-container"
                                [data]="viewData.offerDetailsData">
    </ifbp-offer-details-section>
    <ifbp-offer-requirements-section class="section-container"
                                     [data]="viewData.offerRequirementsData">
    </ifbp-offer-requirements-section>
    <ifbp-submission-details-footer-section class="section-container"
                                            [notInterestedActionData]="viewData.notInterestedActionData"
                                            [appBarActionsData]="viewData.appBarActions"
                                            (appBarActionInvoke)="onAppBarActionInvoke($event, viewData.application.id)">
    </ifbp-submission-details-footer-section>
  </article>
</ng-container>
