<section class="container">
  <h2 class="heading">{{ loan?.productCode | productCodeLabel }}</h2>
  <div class="details">
    <div class="item"
         *ngIf="[BrokerStatus.OPEN, BrokerStatus.FUNDED].includes(brokerStatus)">
      <p class="item__value"
         *ngIf="loan?.loanInfo?.availableFunds || loan?.loanInfo?.availableFunds === 0; else notAvailable">
        {{ loan.loanInfo.availableFunds | currency }}
      </p>
      <p class="item__label">Available funds</p>
    </div>
    <div class="item"
         *ngIf="[BrokerStatus.ABANDONED, BrokerStatus.CLOSED].includes(brokerStatus)">
      <p class="item__value"
         *ngIf="loan?.closeDate; else notAvailable">
        {{ loan.closeDate | date : "M/d/yy"}}
      </p>
      <p class="item__label">{{brokerStatus === BrokerStatus.ABANDONED ? "Close date" : "Paid off date"}}</p>
    </div>
    <div class="item">
      <p class="item__value"
         *ngIf="loan?.loanInfo?.creditLimit; else notAvailable">
        {{ loan.loanInfo.creditLimit | currency : 'USD' : 'symbol' : '1.0-0' }}
      </p>
      <p class="item__label">{{ loan?.productCode === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</p>
    </div>
    <div class="item">
      <p class="item__value"
         *ngIf="repaymentTermValue; else notAvailable">
        {{ repaymentTermValue }}
      </p>
      <p class="item__label">Term</p>
    </div>
    <div class="item">
      <p class="item__value"
         *ngIf="loan?.offer?.paymentFrequency; else notAvailable">
        {{ loan.offer.paymentFrequency | paymentFrequencyLabel }}
      </p>
      <p class="item__label">Payment frequency</p>
    </div>
  </div>
  <ng-template #notAvailable>
    <p class="item__value">N/A</p>
  </ng-template>
</section>
