import { Injectable } from "@angular/core";
import { BrokerApiKeyEditData, CreateBrokerApiKeyData } from "common";

@Injectable()
export class BrokerApiKeyDetailsFacade {
  constructor() {}

  prepareCreateRequest(data: BrokerApiKeyEditData): CreateBrokerApiKeyData {
    return {
      active: data.active ?? false,
      description: data.description ?? "",
      name: data.name ?? ""
    }
  }
}
