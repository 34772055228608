<ng-container *ngIf="viewData">
  <article class="container">
    <ifbp-submission-details-header-section class="section-container"
                                            [data]="viewData.headerData">
    </ifbp-submission-details-header-section>
    <ifbp-activated-details-loan-section class="section-container"
                                         [loan]="viewData.loan"
                                         [brokerStatus]="viewData.application.brokerStatus">
    </ifbp-activated-details-loan-section>
    <ifbp-activated-details-draw-section class="section-container"
                                         [brokerStatus]="viewData.application.brokerStatus"
                                         [drawData]="viewData.loan?.initialDraw"
                                         [transactionStatusLabel]="viewData.transactionStatusLabel">
    </ifbp-activated-details-draw-section>
    <ifbp-submission-details-footer-section class="section-container"
                                            [notInterestedActionData]="viewData.notInterestedActionData">
    </ifbp-submission-details-footer-section>
  </article>
</ng-container>
