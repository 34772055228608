import { Injectable } from "@angular/core";
import { SubmissionsFacade } from "../../domain/+state/submissions.facade";
import { Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { SubmissionListComponentData } from "./submission-list.model";
import { map } from "rxjs/operators";
import { SubmissionData, SubmissionQueryParams } from "../../domain/models/submissions.model";
import { NotInterestedActionInputData } from "../not-interested-action/not-interested-action.model";
import { FilterChip } from "../../shared/filters-chip-list/filters-chip.model";
import { FilterRequest } from "../../shared/filters/filter.model";
import { FilterHelper } from "../../shared/filters/filter.helper";
import { adaptFilterChips, adaptSubmissionFilters, removeFiltersByChips } from "./submission-list.data";


@Injectable()
export class SubmissionListFacade {
  constructor(
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly router: Router
  ) { }

  getComponentData(queryParams: Params, isFirstLoad: boolean): Observable<SubmissionListComponentData> {
    const filters: SubmissionQueryParams = adaptSubmissionFilters(queryParams, isFirstLoad);

    const sort = filters?.sort;
    let sortDirection: string;
    let sortActive: string;
    if (sort) {
      sortDirection = sort.startsWith("-") ? "desc" : "asc";
      sortActive = sortDirection === "desc" ? sort.slice(1) : sort;
    }

    return this.submissionsFacade.getSubmissions(filters).pipe(map(data => {
      const filtersChipList = adaptFilterChips(filters);
      const totalCount = data.totalCount;
      const submissionSummaryList = data.values.map(submission => {
        return {
          submission,
          notInterestedActionData: this.initNotInterestedActionData(submission)
        };
      });
      return {
        filters,
        filtersChipList,
        sortActive,
        sortDirection,
        submissionSummaryList,
        totalCount
      }
    }));
  }

  private initNotInterestedActionData(submission: SubmissionData): NotInterestedActionInputData {
    return {
      applicationId: submission.id,
      brokerStatus: submission.brokerStatus,
      createOnDate: new Date(submission.createdOn),
      previousStage: submission.previousStage
    }
  }

  goToSubmissionDetails(submission: SubmissionData) {
    this.submissionsFacade.goToSubmissionDetails(submission.id, submission.brokerStatus);
  }

  removeFiltersByChips(currentFilters: SubmissionQueryParams, removedChips: FilterChip[]): void {
    const filters = removeFiltersByChips<SubmissionQueryParams>(currentFilters, removedChips);
    this.filter({ filters });
  }

  filter(data: FilterRequest<SubmissionQueryParams>): void {
    const params = FilterHelper.filtersToParams<SubmissionQueryParams>(data);
    this.router.navigate(['submissions'], { replaceUrl: true, queryParams: params });
  }
}
