import { NgModule } from "@angular/core";
import { OfferDetailsSectionComponent } from "./offer-details-section.component";
import { SubmissionDetailsSharedComponentsModule } from "../../components/submission-details-shared-components.module";
import { OfferShareActionsModule } from "../offer-share-actions/offer-share-actions.module";
import { OfferDetailsPanelModule } from "../offer-details-panel/offer-details-panel.module";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule,
    OfferShareActionsModule,
    OfferDetailsPanelModule,
  ],
  declarations: [
    OfferDetailsSectionComponent,
  ],
  exports: [
    OfferDetailsSectionComponent,
  ]
})
export class OfferDetailsSectionModule { }
