<mat-dialog-content>
  <h2 class="heading">Email bank link</h2>
  <p class="description">{{data.accountName}} has been approved for funding from {{data.companyName}}. Send bank link details to the merchant’s email below. We use <a href="https://plaid.com/" target="_blank">Plaid</a> to securely link bank accounts.</p>
  <form [formGroup]="form">
    <mat-form-field class="form-field"
                    appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput
             formControlName="email"
             cdkFocusInitial
             type="text"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions"
                    [value]="option">
          <span>{{option}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error>Please enter a valid email address.</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<div class="actions">
  <button mat-button
          [mat-dialog-close]="undefined">CANCEL</button>
  <button mat-button
          [disabled]="!form.valid"
          color="primary"
          (click)="sendBankLinkEmail()">
    SEND
  </button>
</div>
