import { SubmissionDetailsHeaderData } from "../../submission-details.model";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { filterShareableOfferBundles, getContactString } from "../../submission-details.data";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { AppBarAction, ApplicationOfferBundleData, ButtonColor, DialogActionData, OfferBundleCheckoutRequirement, OfferBundleCheckoutRequirementsSummary, OfferBundleStatus, ProductCode } from "common";
import { OfferDetailsActionType, OfferListData, OfferPanelsData } from "./offer-details.model";
import { Injectable } from "@angular/core";
import { OfferRequirementsAdapter } from "../../shared/modules/offer-requirements-section/offer-requirements-section.data";
import { AdaptOfferRequirementsSectionInputData, OfferRequirementsSectionData } from "../../shared/modules/offer-requirements-section/offer-requirements-section.model";
import { ApplicationData } from "../../../../domain/models/application.model";
import { OfferDetailsPanelAdapter } from "../../shared/modules/offer-details-panel/offer-details-panel.data";
import { OfferDetailsPanelInputData } from "../../shared/modules/offer-details-panel/offer-details-panel.model";

export const getOfferDetailsHeaderData = (brokerStatus: BrokerStatus, companyName?: string): SubmissionDetailsHeaderData | undefined => {
  switch (brokerStatus) {
    case BrokerStatus.OFFER:
      return offerStatusDetailsHeaderData(companyName);

    case BrokerStatus.CONDITIONAL_OFFER:
      return conditionalOfferStatusDetailsHeaderData(companyName);

    default:
      return undefined;
  }
}

const offerStatusDetailsHeaderData = (companyName?: string): SubmissionDetailsHeaderData => {
  return {
    title: "Congratulations!",
    subtitle: `${companyName ?? "N/A"} has been approved for funding. Follow the steps below to submit an offer for review.`,
  }
}

const conditionalOfferStatusDetailsHeaderData = (companyName?: string): SubmissionDetailsHeaderData => {
  return {
    title: "Congratulations!",
    subtitle: `${companyName ?? "N/A"} has been conditionally approved for funding. Subject to a final credit review. Follow the steps below to submit an offer for review.`,
  }
}

export const getOfferDetailsAppBarActions = (isSubmitDisabled: boolean): AppBarAction[] => {
  return [{
    id: OfferDetailsActionType.SubmitForReview,
    label: "SUBMIT FOR REVIEW",
    buttonType: "button",
    buttonAppearance: "flat",
    buttonColor: "primary",
    disabled: isSubmitDisabled
  }]
}

export const getSubmitConfirmationDialogData = (advisorData: AdvisorData): DialogActionData => {
  const contactString = getContactString(advisorData);

  return {
    body: `We are currently reviewing the Closing Documents and will reach out to you shortly. ${contactString}`,
    title: "Submitted for review",
    buttons: [
      {
        label: "OK",
        buttonColor: ButtonColor.Primary,
        action: true,
      }
    ]
  }
}

@Injectable()
export class OfferSubmissionAdapter {
  constructor(
    private readonly offerRequirementsAdapter: OfferRequirementsAdapter,
    private readonly panelAdapter: OfferDetailsPanelAdapter
  ) { }

  adaptOfferListSectionData = (
    app: ApplicationData,
    filteredOfferBundles: ApplicationOfferBundleData[],
    checkoutRequirementsSummary: OfferBundleCheckoutRequirementsSummary[]
  ): OfferListData | undefined => {
    const offerPanelsData = this.adaptPanelsData(app, filteredOfferBundles, checkoutRequirementsSummary);
    const filteredShareableOfferBundles = filterShareableOfferBundles(filteredOfferBundles) as ApplicationOfferBundleData[];

    return {
      offerPanelsData,
      shareActionsInputData: { app, filteredOfferBundles: filteredShareableOfferBundles },
    }
  }

  private adaptPanelsData(
    app: ApplicationData,
    offerBundles: ApplicationOfferBundleData[],
    checkoutRequirementsSummary: OfferBundleCheckoutRequirementsSummary[]
  ): OfferPanelsData {
    let offers: OfferPanelsData = {
      locOffers: [],
      termOffers: [],
    }

    offers = offerBundles.reduce((acc, offerBundle) => {
      if (!offerBundle?.offers || offerBundle.offers.length === 0) {
        return acc;
      }

      const offer = offerBundle.offers[0];
      const checkoutRequirements = checkoutRequirementsSummary.find(item => item.offerBundleId === offerBundle.id)?.checkoutRequirements;
      const panelInputData: OfferDetailsPanelInputData = {
        app,
        offer,
        offerBundleId: offerBundle.id,
        checkoutRequirements,
        isCustomerOfferViewRedirectAvailable: true,
        isSelectable: true,
        isRejectable: offerBundles.length > 1,
        isMarkedAsSelected: false,
        isRejected: offerBundle.status === OfferBundleStatus.Rejected,
      }

      if (offer.productCode === ProductCode.LOC) {
        acc.locOffers.push(this.panelAdapter.adaptLocPanelData(panelInputData));
        return acc;
      }

      if (offer.productCode === ProductCode.Term) {
        acc.termOffers.push(this.panelAdapter.adaptTermPanelData(panelInputData));
        return acc;
      }

      return acc;
    }, offers);

    return {
      locOffers: offers.locOffers.sort((a, b) => b.headerData.amount - a.headerData.amount),
      termOffers: offers.termOffers.sort((a, b) => b.headerData.amount - a.headerData.amount),
    }
  }

  adaptEmptyOfferRequirementsSectionData = (): OfferRequirementsSectionData | undefined => {
    return this.offerRequirementsAdapter.adaptOfferRequirementsSectionData({ showEmpty: true });
  }

  adaptOfferRequirementsSectionData = (checkoutRequirements: OfferBundleCheckoutRequirement[]): OfferRequirementsSectionData | undefined => {
    const inputData = this.getOfferRequirementsInutData(checkoutRequirements);
    return this.offerRequirementsAdapter.adaptOfferRequirementsSectionData(inputData);
  }

  private getOfferRequirementsInutData(checkoutRequirements: OfferBundleCheckoutRequirement[]): AdaptOfferRequirementsSectionInputData {
    return {
      checkoutRequirements,
      isReadonly: false,
      showEmpty: false,
    }
  }
}
