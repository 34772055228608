import { Injectable } from "@angular/core";
import { SubmissionsFacade } from "../../../../domain/+state/submissions.facade";
import { combineLatest, Observable } from "rxjs";
import { ApplicationDocumentsSummary, ProcessingDetailsAdapter, ProcessingDetailsComponentData } from "./processing-details.model";
import { map, switchMap } from "rxjs/operators";
import { ApplicationDocumentData, ApplicationDocumentsQueryResult } from "../../../../domain/models/application.model";
import { DocumentCategory } from "common";
import { getProcessingDetailsHeaderData, getSubmissionDetailsBannerData } from "./processing-details.data";
import { SubmissionDetailsFacade } from "../../submission-details.facade";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";

@Injectable()
export class ProcessingDetailsFacade {
  constructor(
    private readonly detailsFacade: SubmissionDetailsFacade,
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly adapter: ProcessingDetailsAdapter
  ) { }

  getComponentData$(applicationId: number): Observable<ProcessingDetailsComponentData> {
    return this.detailsFacade.getComponentBaseData$(applicationId)
      .pipe(switchMap((data: SubmissionDetailsBaseComponentData) => {
        return this.getProcessingDetailsComponentData$(data);
      }));
  }

  private getProcessingDetailsComponentData$(data: SubmissionDetailsBaseComponentData): Observable<ProcessingDetailsComponentData> {
    return combineLatest([
      this.submissionsFacade.getApplicationDocuments(<number>data.application.id),
      this.submissionsFacade.getContactsData({ applicationId: <number>data.application.id }),
      this.submissionsFacade.getAdvisorData$(data.application.id)
    ]).pipe(map(([applicationDocuments, contacts, advisorData]) => {
      return {
        ...data,
        sections: this.adapter.adaptProcessingDetailsBasicSections(data.application, contacts, data.account),
        applicationDocumentsSummary: this.prepareApplicationDocumentsSummary(applicationDocuments),
        contacts,
        headerData: getProcessingDetailsHeaderData(data.application.brokerStatus, advisorData),
        bannerData: getSubmissionDetailsBannerData(data.application)
      };
    }))
  }

  private prepareApplicationDocumentsSummary(applicationDocuments: ApplicationDocumentsQueryResult): ApplicationDocumentsSummary | undefined {
    if (!applicationDocuments || applicationDocuments.totalCount === 0) {
      return undefined;
    }

    const signedApplicationDocuments: ApplicationDocumentData[] = [];
    const bankStatementDocuments: ApplicationDocumentData[] = [];

    applicationDocuments.values.forEach((document) => {
      if (document.category === DocumentCategory.Application) {
        signedApplicationDocuments.push(document);
        return;
      }
      if (document.category === DocumentCategory.BankStatement) {
        bankStatementDocuments.push(document);
        return;
      }
    });

    return {
      signedApplicationDocuments,
      bankStatementDocuments
    }
  }
}
