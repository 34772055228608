import { OfferDetailsData } from "../../shared/modules/offer-details-section/offer-details-section.model";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { AppBarAction } from "common";

export interface ContractDetailsComponentData extends SubmissionDetailsBaseComponentData {
  offerDetailsData?: OfferDetailsData;
  appBarActions?: AppBarAction[];
}

export enum ContractDetailsActionType {
  SendCheckout = "sendCheckout",
  ResendCheckout = "resendCheckout",
}
