<section class="container">
  <ng-container *ngIf="data?.productCode && data?.panelData; else notAvailable">
    <header class="header">
      <ifbp-product-name-section-header [productCode]="data.productCode"></ifbp-product-name-section-header>
      <div class="header__actions">
        <button *ngIf="data?.isEditable"
                mat-button
                preventDoubleClicks
                class="update-action"
                (singleClick)="onClickUpdateOffer(data)">
                <span class="update-action update-action--long">UPDATE OFFER</span>
                <span class="update-action update-action--short">UPDATE</span>
        </button>
        <ifbp-offer-share-actions [inputData]="data.shareActionsInputData"></ifbp-offer-share-actions>
      </div>
    </header>
    <section>
      <mat-accordion>
        <ifbp-offer-details-panel [data]="data?.panelData"></ifbp-offer-details-panel>
      </mat-accordion>
    </section>
  </ng-container>
  <ng-template #notAvailable>
    <span class="not-available">Offer details are not available</span>
  </ng-template>
</section>
