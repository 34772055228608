import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { OfferShareActionsAdapter } from "../../offer-share-actions.data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, startWith } from "rxjs/operators";
import { OfferShareActionsData, OfferShareActionsOfferPreviewData } from "../../offer-share-actions.model";
import { EnumValueLabelPipe, PaymentFrequency, PaymentFrequencyLabel, ProductCode, ProductCodeLabel } from "common";
import { CurrencyPipe } from "@angular/common";

@UntilDestroy()
@Component({
  selector: "ifbp-generate-email-offer.",
  templateUrl: "./generate-email-offer.component.html",
  styleUrls: ["./generate-email-offer.component.scss"],
  providers: [OfferShareActionsAdapter],
})
export class GenerateEmailOfferComponent implements OnInit {
  form: UntypedFormGroup;
  mailTo: string;
  locOffers: OfferShareActionsOfferPreviewData[];
  termOffers: OfferShareActionsOfferPreviewData[];

  readonly PaymentFrequency = PaymentFrequency;
  readonly PaymentFrequencyLabel = PaymentFrequencyLabel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OfferShareActionsData,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly enumValueLabelPipe: EnumValueLabelPipe,
    private readonly currencyPipe: CurrencyPipe,
  ) { }

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: this.data?.contactEmails?.join("; ") ?? "",
    });
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(200), startWith(this.form.value))
      .subscribe(data => {
        this.mailTo = this.prepareMailToAttribute(data?.email);
      })
  }

  private initData() {
    this.locOffers = this.data?.offers?.filter (offer => offer.productCode === ProductCode.LOC);
    this.termOffers = this.data?.offers?.filter (offer => offer.productCode === ProductCode.Term);
  }

  private prepareMailToAttribute(emailTo: string): string {
    if (!this.locOffers?.length && !this.termOffers?.length) {
      return "";
    }

    const offers = [...this.locOffers, ...this.termOffers];

    if (offers.length === 1) {
      const productName = this.enumValueLabelPipe.transform(offers[0].productCode, ProductCode, ProductCodeLabel);
      const emailBody = `Congratulations! ${this.data.accountName} has been approved for a ${productName} of ${this.getFormattedAmount(offers[0].amount)} from ${this.data.companyName}. \n\nSee the offer details here: ${this.data.summaryPreviewUrl}`;
      return `mailTo:${emailTo}?Subject=Your ${this.data.companyName} ${productName} Offer&body=${encodeURIComponent(emailBody)}`;
    }

    const offerList = offers.map((offer, index) => {
      const productName = this.enumValueLabelPipe.transform(offer.productCode, ProductCode, ProductCodeLabel);
      return `${index + 1}. ${productName} of ${this.getFormattedAmount(offer.amount)},`;
    });
    const emailBody = `Congratulations! ${this.data.accountName} has been approved for funding from ${this.data.companyName} for: \n\n${offerList.join("\n")} \n\nSee the offer details here: ${this.data.summaryPreviewUrl}`;
    return `mailTo:${emailTo}?Subject=Your ${this.data.companyName} Funding Offer&body=${encodeURIComponent(emailBody)}`;
  }


  private getFormattedAmount(amount: number): string {
    return <string>this.currencyPipe.transform(amount);
  }

  static show(
    dialog: MatDialog,
    data: OfferShareActionsData,
  ) {
    dialog.open(GenerateEmailOfferComponent, {
      width: "560px",
      data: { ...data },
      closeOnNavigation: true,
    });
  }
}
