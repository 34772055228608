
import { NgModule } from "@angular/core";
import { SubmissionsSharedModule } from "../../../../shared/submissions-shared.module";
import { NotInterestedActionModule } from "../../../not-interested-action/not-interested-action.module";
import { NotInterestedDialogModule } from "../../../not-interested-dialog/not-interested-dialog.module";
import { SubmissionDetailsHeaderSectionComponent } from "./submission-details-header-section/submission-details-header-section.component";
import { SubmissionDetailsFooterSectionComponent } from './submission-details-footer-section/submission-details-footer-section.component';
import { SubmissionDetailsBasicSectionComponent } from "./submission-details-basic-section/submission-details-basic-section.component";
import { SubmissionDetailsBannerSectionComponent } from "./submission-details-banner-section/submission-details-banner-section.component";
import { ProductNameSectionHeaderComponent } from './product-name-section-header/product-name-section-header.component';

@NgModule({
  imports: [
    SubmissionsSharedModule,
    NotInterestedActionModule,
    NotInterestedDialogModule,
  ],
  declarations: [
    SubmissionDetailsHeaderSectionComponent,
    SubmissionDetailsFooterSectionComponent,
    SubmissionDetailsBasicSectionComponent,
    SubmissionDetailsBannerSectionComponent,
    ProductNameSectionHeaderComponent,
  ],
  exports: [
    SubmissionsSharedModule,
    SubmissionDetailsHeaderSectionComponent,
    SubmissionDetailsFooterSectionComponent,
    SubmissionDetailsBasicSectionComponent,
    SubmissionDetailsBannerSectionComponent,
    ProductNameSectionHeaderComponent
  ]
})
export class SubmissionDetailsSharedComponentsModule { }
