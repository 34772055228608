<ng-container *ngIf="productCode">
  <div class="container">
    <ng-container [ngSwitch]="productCode">
      <ng-container *ngSwitchCase="ProductCode.LOC">
        <mat-icon class="loc-icon"
                  [ngClass]="{'loc-icon--primary': type === 'primary', 'loc-icon--secondary': type === 'secondary'}">
          data_saver_off
        </mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ProductCode.Term">
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'primary'">
            <mat-icon class="term-icon term-icon--primary">assessment</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'secondary'">
            <mat-icon class="term-icon term-icon--secondary">bar_chart</mat-icon>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <span class="text"
          [ngClass]="{'text--primary': type === 'primary', 'text--secondary': type === 'secondary'}">
      {{productCode | enumValueLabel: ProductCode : ProductCodeLabel}}
    </span>
  </div>
</ng-container>
