import { Injectable } from "@angular/core";
import { CheckoutRequirementDocumentType, CheckoutRequirementStatus, OfferBundleCheckoutRequirement, SimpleDocumentFile } from "common";
import { AdaptOfferRequirementsSectionInputData, FilteredOfferBundleCheckoutRequirementsData, OfferRequirementData, OfferRequirementDocumentData, OfferRequirementsData, OfferRequirementsIndicatorType, OfferRequirementsSectionData } from "./offer-requirements-section.model";

const maxCheckoutRequirementDocumentsCount: number = 25;

@Injectable()
export class OfferRequirementsAdapter {
  adaptOfferRequirementsSectionData = (inputData?: AdaptOfferRequirementsSectionInputData): OfferRequirementsSectionData | undefined => {
    if (!inputData) {
      return undefined;
    }

    if (inputData.showEmpty) {
      return {
        showEmpty: true,
      }
    }

    const filteredRequirements = this.filterCheckoutRequirements(inputData?.checkoutRequirements);
    if (!filteredRequirements) {
      return {
        isRequirementsDataValid: true,
        showEmpty: false,
      }
    }

    const requirements = this.adaptRequirementsData(filteredRequirements, !!inputData.isReadonly);
    return {
      isRequirementsDataValid: this.checkIfIsRequirementsDataValid(requirements),
      requirements,
      isReadonly: inputData.isReadonly,
      showEmpty: false,
      customDescription: inputData.customDescription,
    }
  }

  filterCheckoutRequirements(requirements?: OfferBundleCheckoutRequirement[]): FilteredOfferBundleCheckoutRequirementsData | undefined {
    if (!requirements?.length) {
      return undefined;
    }

    const filteredRequirements: FilteredOfferBundleCheckoutRequirementsData = {
      closingDocumentRequirements: [],
      nextStepsRequirements: [],
    }

    return requirements.reduce((acc, item) => {
      if (item.status === CheckoutRequirementStatus.Duplicate || item.status === CheckoutRequirementStatus.Waived) {
        return acc;
      }

      if (item.requiredDocumentType === CheckoutRequirementDocumentType.Document) {
        acc.closingDocumentRequirements.push(item);
        return acc;
      }

      acc.nextStepsRequirements.push(item);
      return acc;

    }, filteredRequirements);
  }

  private adaptRequirementsData(filteredRequirements: FilteredOfferBundleCheckoutRequirementsData, isReadonly: boolean): OfferRequirementsData {
    return {
      closingDocuments: filteredRequirements.closingDocumentRequirements.map((item) => this.adaptClosingDocumentRequirement(item, isReadonly)),
      nextSteps: filteredRequirements.nextStepsRequirements.map((item) => this.adaptNextStepRequirement(item)),
    }
  }

  private checkIfIsRequirementsDataValid(requirements: OfferRequirementsData) {
    return requirements.closingDocuments.every(item => item.isValid) && requirements.nextSteps.every(item => item.isValid);
  }

  private adaptClosingDocumentRequirement(requirement: OfferBundleCheckoutRequirement, isReadonly: boolean): OfferRequirementData {
    const documents = this.adaptOfferRequirementDocumentData(requirement);
    const maxDocumentsCountToUpload = this.getMaxDocumentsCountToUpload(documents?.length);
    return {
      requirementId: requirement.id,
      indicatorType: this.getIndicatorType(requirement),
      name: `${requirement.requiredDocument} ${requirement.status === CheckoutRequirementStatus.Required ? "(Required)" : ""}`,
      maxDocumentsCountToUpload,
      documents,
      description: this.getDocumentRequirementDescription(requirement, maxDocumentsCountToUpload, isReadonly),
      isUploadActionAvailable: this.checkIfIsUploadActionAvailable(requirement, maxDocumentsCountToUpload, isReadonly),
      category: requirement.category,
      isValid: this.checkIfIsRequirementValid(requirement, documents?.length)
    }
  }

  private adaptNextStepRequirement(requirement: OfferBundleCheckoutRequirement): OfferRequirementData {
    return {
      requirementId: requirement.id,
      indicatorType: OfferRequirementsIndicatorType.Incomplete,
      name: requirement.requiredDocument,
      description: this.getNextStepRequirementDescription(requirement),
      isValid: true
    }
  }

  private adaptOfferRequirementDocumentData(requirement: OfferBundleCheckoutRequirement): OfferRequirementDocumentData[] {
    return requirement?.documents?.map((item: SimpleDocumentFile) => {
      return {
        id: item.id,
        name: item.filename,
        isRemovable: item.isTemporary,
        isDownloadable: true,
        isLabelVisible: item.isTemporary,
      }
    })
  }

  private checkIfIsUploadActionAvailable(requirement: OfferBundleCheckoutRequirement, maxDocumentsCountToUpload: number, isReadonly: boolean): boolean {
    return requirement.status !== CheckoutRequirementStatus.Approved && maxDocumentsCountToUpload > 0 && !isReadonly;
  }

  private getIndicatorType(requirement: OfferBundleCheckoutRequirement): OfferRequirementsIndicatorType {
    if (requirement.status === CheckoutRequirementStatus.Approved) {
      return OfferRequirementsIndicatorType.Met;
    }

    if (requirement.isFileUploaded || requirement.documents?.length > 0) {
      return OfferRequirementsIndicatorType.InProgress;
    }

    return OfferRequirementsIndicatorType.Incomplete;
  }

  private getDocumentRequirementDescription(requirement: OfferBundleCheckoutRequirement, maxDocumentsCountToUpload: number, isReadonly: boolean): string {
    const additionalNotes = requirement.notes ? `Additional notes: ${requirement.notes}` : "";
    if (requirement.status === CheckoutRequirementStatus.Approved) {
      return additionalNotes;
    }

    if (requirement.isFileUploaded && !requirement.documents?.length) {
      return `Received via alternative method outside Broker Portal. ${additionalNotes}`;
    }

    const isUploadAvailable = this.checkIfIsUploadActionAvailable(requirement, maxDocumentsCountToUpload, isReadonly);
    return isUploadAvailable ? `Upload any PNG, JPEG, JPG, PDF copies. ${additionalNotes}` : additionalNotes;
  }

  private getNextStepRequirementDescription(requirement: OfferBundleCheckoutRequirement): string {
    return requirement.notes ? `Additional notes: ${requirement.notes}` : "";
  }

  private getMaxDocumentsCountToUpload(currentDocumentsLength?: number): number {
    const max = maxCheckoutRequirementDocumentsCount - (currentDocumentsLength ?? 0);
    return max > 0 ? max : 0;
  }

  private checkIfIsRequirementValid(requirement: OfferBundleCheckoutRequirement, documentsLength?: number): boolean {
    if (requirement.status !== CheckoutRequirementStatus.Required
      || requirement.isFileUploaded
      || documentsLength && documentsLength > 0
    ) {
      return true;
    }
    return false;
  }
}
