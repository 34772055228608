import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { SubmissionDetailsBasicSectionDisplayMode } from '../../components/submission-details-basic-section/submission-details-basic-section.model';
import { OfferDetailsPanelData } from './offer-details-panel.model';
import { SubmissionDetailsActionsService } from '../../../actions/submission-details-actions.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { OfferBundleStatus } from 'common';

@Component({
  selector: 'ifbp-offer-details-panel',
  templateUrl: './offer-details-panel.component.html',
  styleUrls: ['./offer-details-panel.component.scss']
})
export class OfferDetailsPanelComponent implements OnChanges {
  @ViewChild('panel') panel: MatExpansionPanel;
  isPanelOpened: boolean = false;

  readonly DisplayMode = SubmissionDetailsBasicSectionDisplayMode;

  @Input()
  data: OfferDetailsPanelData;

  constructor(private readonly actions: SubmissionDetailsActionsService) { }

  ngOnChanges(): void {
    if (this.data.isRejected) {
      this.panel.close();
    }
  }

  onCustomerOfferViewClick(detailsPreviewUrl: string) {
    this.actions.redirectCustomerOfferDetailsView(detailsPreviewUrl);
  }

  panelToggled(isOpen: boolean) {
    this.isPanelOpened = isOpen;
  }

  onClickPanel(data: OfferDetailsPanelData) {
    if (data.isRejected) {
      return;
    }
    this.panel.toggle();
  }

  onClickSelect(data: OfferDetailsPanelData) {
    if (!data.isSelectable || data.isRejected) {
      return;
    }

    this.actions.updateOfferBundleStatus(this.prepareUpdateStatusData(data, OfferBundleStatus.Accepted));
  }

  onClickReject(data: OfferDetailsPanelData) {
    if (!data.isRejectable || data.isRejected) {
      return;
    }

    this.actions.updateOfferBundleStatus(this.prepareUpdateStatusData(data, OfferBundleStatus.Rejected));
  }

  onClickReactivate(data: OfferDetailsPanelData) {
    if (!data.isRejectable || !data.isRejected) {
      return;
    }

    this.actions.updateOfferBundleStatus(this.prepareUpdateStatusData(data, OfferBundleStatus.Pending));
  }

  private prepareUpdateStatusData(data: OfferDetailsPanelData, status: OfferBundleStatus) {
    return {
      applicationId: data.applicationId,
      offerBundleId: data.offerBundleId,
      status
    }
  }
}
