import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { BrokerApiKeyListComponent } from './features/broker-api-key-list/broker-api-key-list.component';
import { BrokerApiKeyDetailsComponent } from './features/broker-api-key-details/broker-api-key-details.component';
import { BrokerApiKeyModule as CommonBrokerApiKeyModule } from 'common';


@NgModule({
  declarations: [
    BrokerApiKeyListComponent,
    BrokerApiKeyDetailsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    CommonBrokerApiKeyModule
  ] 
})
export class AdminModule { }
