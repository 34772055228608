<ng-container *ngIf="requirement">
  <div class="requirement">
    <div class="header">
      <div class="header__main">
        <ng-container [ngSwitch]="requirement.indicatorType">
          <ng-container *ngSwitchCase="IndicatorType.Met">
            <mat-icon class="icon mat-green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="IndicatorType.InProgress">
            <mat-icon class="icon mat-orange">sticky_note_2</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="IndicatorType.Incomplete">
            <mat-icon class="icon mat-gray"
                      fontSet="material-icons-outlined">circle</mat-icon>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
        <div class="text">
          <span>{{requirement.name}}</span>
          <span *ngIf="requirement.description"
                class="text__description">{{requirement.description}}</span>
        </div>
      </div>
      <button *ngIf="requirement.isUploadActionAvailable"
              class="action"
              mat-button
              color="primary"
              (click)="onUploadDocumentClick()">
        <div class="action__body">
          <mat-icon class="action__icon">file_upload</mat-icon>
          <span class="action__text">UPLOAD</span>
        </div>
      </button>
    </div>
    <div class="documents"
         *ngIf="requirement.documents?.length > 0">
      <div class="document"
           *ngFor="let document of requirement.documents">
        <button *ngIf="document.isRemovable"
                class="document__button"
                mat-icon-button
                type="button"
                preventDoubleClicks
                (singleClick)="onRemoveDocumentClick(document)">
          <mat-icon class="document__icon">close</mat-icon>
        </button>
        <span [ngClass]="{'document__download': document.isDownloadable}"
              preventDoubleClicks
              (singleClick)="onDocumentNameClick(document)">
          {{ document.name }}
        </span>
        <span *ngIf="document.isLabelVisible"
              class="document__label">
          Auto-saved
        </span>
      </div>
    </div>
  </div>
</ng-container>
