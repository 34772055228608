import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { OfferListData } from '../../offer-details.model';
import { ProductCode } from 'common';
import { MatAccordion } from '@angular/material/expansion';
import { OfferDetailsPanelData } from '../../../../shared/modules/offer-details-panel/offer-details-panel.model';

@Component({
  selector: 'ifbp-offer-list-section',
  templateUrl: './offer-list-section.component.html',
  styleUrls: ['./offer-list-section.component.scss']
})
export class OfferListSectionComponent implements OnChanges {
  @ViewChild('locAccordion') locAccordion: MatAccordion;
  @ViewChild('termAccordion') termAccordion: MatAccordion;
  readonly ProductCode = ProductCode;

  isOffersDataAvailable: boolean;
  isLocOfferPanelsDataAvailable: boolean;
  isTermOfferPanelsDataAvailable: boolean;

  @Input()
  data: OfferListData;

  trackByFn(_: number, item: OfferDetailsPanelData) {
    return item.offerBundleId;
  }

  ngOnChanges(): void {
    this.isLocOfferPanelsDataAvailable = !!this.data?.offerPanelsData?.locOffers?.length;
    this.isTermOfferPanelsDataAvailable = !!this.data?.offerPanelsData?.termOffers?.length;
    this.isOffersDataAvailable = this.data && (this.isLocOfferPanelsDataAvailable || this.isTermOfferPanelsDataAvailable);
  }

  onClickExpandAll() {
    if (this.isLocOfferPanelsDataAvailable) {
      this.locAccordion.openAll();
    }
    if (this.isTermOfferPanelsDataAvailable) {
      this.termAccordion.openAll()
    }
  }

  onClickCollapseAll() {
    if (this.isLocOfferPanelsDataAvailable) {
      this.locAccordion.closeAll();
    }
    if (this.isTermOfferPanelsDataAvailable) {
      this.termAccordion.closeAll()
    }
  }
}
