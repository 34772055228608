import { NgModule } from "@angular/core";
import { SubmissionDetailsSharedModule } from "../../shared/submission-details-shared.module";
import { ContractDetailsComponent } from './contract-details.component';
@NgModule({
  imports: [
    SubmissionDetailsSharedModule,
  ],
  declarations: [
    ContractDetailsComponent,
  ],
  exports: [ContractDetailsComponent]
})
export class ContractDetailsModule { }
