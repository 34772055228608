<ng-container *ngIf="data?.showEmpty">
  <section class="container">
    <header class="header header--disabled">
      <h2 class="heading heading--disabled">
        <span class="heading__icon heading__icon--disabled">2</span>
        <span class="heading__text">Upload documents</span>
      </h2>
    </header>
  </section>
</ng-container>

<ng-container *ngIf="isDataAvailable">
  <section class="container">
    <header class="header">
      <ng-container *ngIf="data.isReadonly">
        <h2 class="heading">
          <mat-icon class="heading__icon"
                    fontSet="material-icons-round">file_present</mat-icon>
          <span class="heading__text">Uploaded</span>
        </h2>
      </ng-container>
      <ng-container *ngIf="!data.isReadonly">
        <h2 class="heading">
          <span class="heading__icon">2</span>
          <span class="heading__text">Upload documents</span>
        </h2>
        <ng-container *ngIf="data.customDescription">
          <p class="subheading"
             [innerHTML]="data.customDescription | safePureHtml"></p>
        </ng-container>
        <ng-container *ngIf="!data.customDescription">
          <p class="subheading">Upload the required closing documents and submit the selected offer for review.</p>
        </ng-container>
      </ng-container>
    </header>
    <section *ngIf="isClosingDocsRequirementsAvailable"
             class="section"
             [ngClass]="{'section--error': isClosingDocsRequirementsMarkedAsInvalid}">
      <header class="section-header">
        <h2 class="section-heading">Closing Documents</h2>
      </header>
      <div class="requirements">
        <ng-container *ngFor="let requirement of data.requirements.closingDocuments; last as isLast">
          <ifbp-offer-requirement-panel [requirement]="requirement">
          </ifbp-offer-requirement-panel>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </div>
    </section>
    <p *ngIf="isClosingDocsRequirementsMarkedAsInvalid"
       class="closing-error">
      Upload the required documents
    </p>
    <section *ngIf="isNextStepsRequirementsAvailable"
             class="section section--next-steps">
      <header class="section-header">
        <h2 class="section-heading">Next Step Requirements</h2>
      </header>
      <div class="requirements">
        <ng-container *ngFor="let requirement of data.requirements.nextSteps; last as isLast">
          <ifbp-offer-requirement-panel [requirement]="requirement">
          </ifbp-offer-requirement-panel>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </div>
    </section>
  </section>
</ng-container>
