import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BrokerApiKeysRemote } from "../../infrastructure/broker-api-keys.remote";
import { BrokerApiKeyData, BrokerApiKeyEditData, BrokerApiKeyQueryParams, BrokerApiKeyQueryResult, CreateBrokerApiKeyData, CreateBrokerApiKeySuccessData } from "common";

@Injectable({
  providedIn: "root",
})
export class BrokerApiKeysFacade {
  constructor(private readonly remote: BrokerApiKeysRemote) {}

  getBrokerApiKeys(
    params: BrokerApiKeyQueryParams
  ): Observable<BrokerApiKeyQueryResult> {
    return this.remote.query(params);
  }

  getBrokerApiKeyById(id: number): Observable<BrokerApiKeyData> {
    return this.remote.get(id);
  }

  createBrokerApiKey(request: CreateBrokerApiKeyData): Observable<CreateBrokerApiKeySuccessData> {
    return this.remote.create({...request});
  }
  
  changeBrokerApiKeyStatus(id: number, brokerApiKeyData: BrokerApiKeyEditData): Observable<BrokerApiKeyData> {
    return this.remote.update(id, brokerApiKeyData);
  }

  removeBrokerApiKey(id: number): Observable<BrokerApiKeyData> {
    return this.remote.delete(id);
  }

  updateBrokerApiKey(id: number, brokerApiKeyData: BrokerApiKeyEditData): Observable<BrokerApiKeyData> {
    return this.remote.update(id, brokerApiKeyData);
  }
}
