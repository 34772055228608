import { ConfigService } from "common";

export interface ApiPathsData {
  account: AccountApiPathsData;
  team: TeamApiPathsData;
  submission: SubmissionApiPathsData;
  dictionary: DictionaryApiPathsData;
  dashboard: DashboardApiPathsData;
}

export interface AccountApiPathsData {
  signIn: string
  phoneNumberConfirm: string;
  userCurrent: string;
  broker: (brokerId: number) => string;
  userPassword: (userId: number) => string;
  passwordForgot: string;
  userPasswordReset: string;
  userPasswordCheckToken: (email: string, token: string) => string;
  userRegister: string;
  userRegisterCheckToken: (email: string, token: string) => string;
}

export interface TeamApiPathsData {
  users: string;
  user: (userId: number) => string;
  userTeamInvitation: string;
  userResendInvitation: (userId: number) => string;
}

export interface SubmissionApiPathsData {
  newApplicationAccount: string;
  applicationAccount: (accountId: number) => string;
  loanAccount: (loanId: number) => string;
  newContact: string;
  contact: (contactId: number) => string;
  contacts: string;
  newApplication: string;
  application: (appId: number) => string;
  applications: string;
  applicationDocumentCategory: (appId: number, category: string) => string;
  applicationDocuments: (appId: number) => string;
  applicationDocument: (appId: number, documentId: number) => string;
  applicationDocumentPartner: (appId: number, documentId: number) => string;
  competitors: string;
  applicationCreateLoans: (appId: number) => string;
  applicationResendCheckout: (appId: number) => string;
  checkoutRequirementFile: (id: number, documentId: number) => string;
  checkoutRequirementFileCategory: (id: number, category: string) => string;
  offerBundleStatus: (offerBundleId: number) => string;
  bankLinkingInvitation: (appId: number) => string;
  list: string;
  hints: string;
}

export interface DictionaryApiPathsData {
  lostReasons: string;
}

export interface DashboardApiPathsData {
  widgets: string;
}

export let ApiPaths: ApiPathsData;

export function initApiPaths() {
  ApiPaths = initApiPathsBase(ConfigService.environmentGlobal);
}

function initApiPathsBase(config: any): ApiPathsData {
  return {
    account: {
      signIn: `${config?.oauth?.url}/token`,
      phoneNumberConfirm: `${config?.oauth?.url}/api/phone-number/confirm`,
      userCurrent: `${config?.brokers?.url}/api/user/current`,
      broker: (brokerId: number): string => `${config?.brokers?.url}/api/broker/${brokerId}`,
      userPassword: (userId: number): string => `${config?.brokers?.url}/api/user/${userId}/password`,
      passwordForgot: `${config?.brokers?.url}/api/user/password/forgot`,
      userPasswordReset: `${config?.brokers?.url}/api/user/password/reset`,
      userPasswordCheckToken: (email: string, token: string): string =>
        `${config?.brokers?.url}/api/user/password/check-token?email=${email}&token=${token}`,
      userRegister: `${config?.brokers?.url}/api/user/register`,
      userRegisterCheckToken: (email: string, token: string): string =>
        `${config?.brokers?.url}/api/user/register/check-token?email=${email}&registrationToken=${token}`
    },
    team: {
      users: `${config?.brokers?.url}/api/user`,
      user: (userId: number): string => `${config?.brokers?.url}/api/user/${userId}`,
      userTeamInvitation: `${config?.brokers?.url}/api/user/team-invitation`,
      userResendInvitation: (userId: number): string => `${config?.brokers?.url}/api/user/resend-invitation/${userId}`
    },
    submission: {
      newApplicationAccount: `${config?.applications?.url}/api/account`,
      applicationAccount: (accountId: number): string => `${config?.applications?.url}/api/account/${accountId}`,
      loanAccount: (loanId: number): string => `${config?.loans?.url}/api/account/${loanId}`,
      newContact: `${config?.applications?.url}/api/contact`,
      contact: (contactId: number): string => `${config?.applications?.url}/api/contact/${contactId}`,
      contacts: `${config?.applications?.url}/api/contact`,
      newApplication: `${config?.applications?.url}/api/application`,
      application: (appId: number): string => `${config?.applications?.url}/api/application/${appId}`,
      applications: `${config?.applications?.url}/api/application`,
      applicationDocumentCategory: (appId: number, category: string): string =>
        `${config?.applications?.url}/api/application/${appId}/documents/${category}`,
      applicationDocuments: (appId: number): string =>
        `${config?.applications?.url}/api/application/${appId}/documents`,
      applicationDocument: (appId: number, documentId: number): string =>
        `${config?.applications?.url}/api/application/${appId}/documents/${documentId}`,
      applicationDocumentPartner: (appId: number, documentId: number): string =>
        `${config?.applications?.url}/api/application/${appId}/documents/${documentId}/partner`,
      competitors: `${config?.applications?.url}/api/competitor`,
      applicationCreateLoans: (appId: number): string => `${config?.applications?.url}/api/application/${appId}/create-loans`,
      applicationResendCheckout: (appId: number): string => `${config?.applications?.url}/api/application/${appId}/resend-checkout`,
      checkoutRequirementFile: (id: number, documentId: number): string =>
        `${config?.applications?.url}/api/checkout-requirements/${id}/files/${documentId}`,
      checkoutRequirementFileCategory: (id: number, category: string): string =>
        `${config?.applications?.url}/api/checkout-requirements/${id}/files/${category}`,
      offerBundleStatus: (offerBundleId: number): string => `${config?.applications?.url}/api/offer-bundle/${offerBundleId}/status`,
      bankLinkingInvitation: (appId: number): string => `${config?.banking?.url}/api/banking/linking-invitation/${appId}`,
      list: `${config?.brokers?.url}/api/submissions`,
      hints: `${config?.brokers?.url}/api/submissions/hints`
    },
    dictionary: {
      lostReasons: `${config?.applications?.url}/api/dictionary/lost-reason`
    },
    dashboard: {
      widgets: `${config?.brokers?.url}/api/dashboard`
    }
  }
};
