import { Component, Input, OnChanges } from '@angular/core';
import { ContractDetailsFacade } from './contract-details.facade';
import { ContractDetailsActionType, ContractDetailsComponentData } from './contract-details.model';
import { SubmissionDetailsActionsService } from '../../actions/submission-details-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarAction } from 'common';
import { Router } from '@angular/router';
import { SubmissionDetailsParams } from '../../submission-details.model';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ifbp-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
  providers: [ContractDetailsFacade]
})
export class ContractDetailsComponent implements OnChanges {
  viewData: ContractDetailsComponentData;
  actionInProgress: boolean;

  @Input()
  params: SubmissionDetailsParams;

  constructor(
    private readonly facade: ContractDetailsFacade,
    private readonly actions: SubmissionDetailsActionsService,
    private readonly router: Router,
  ) { }

  ngOnChanges(): void {
    this.initData();
  }

  private initData() {
    return this.facade.getComponentData$(this.params.appId).pipe(untilDestroyed(this))
      .subscribe(data => {
        this.viewData = data;
        this.actions.markDataAsLoaded(true);
      });
  }

  onAppBarActionInvoke(action: AppBarAction, appId: number): void {
    if (this.actionInProgress) {
      return;
    }
    this.actionInProgress = true;

    switch (action.id) {
      case ContractDetailsActionType.SendCheckout:
        this.facade.sendCheckout(appId).pipe(tap(() => this.handleSuccess())).subscribe();
        return;
      case ContractDetailsActionType.ResendCheckout:
        this.facade.resendCheckout(appId).pipe(tap(() => this.handleSuccess())).subscribe();
        return;
      default:
        return;
    }
  }

  private handleSuccess() {
    void this.router.navigate([]);
    this.actionInProgress = false;
  }
}
