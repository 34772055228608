import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AccountFacade } from "../../domain/+state/account.facade";
import { ErrorActionType, ErrorDescriptionType, ErrorsService } from "common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenStatus } from "../../domain/model/account.model";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private accountFacade: AccountFacade,
    private router: Router,
    private errorsService: ErrorsService
  ) {
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    const email = _next.queryParams['email'];
    const token = _next.queryParams['token'];

    if (!email || !token) {
      return this.router.createUrlTree(['error']);
    }

    return this.accountFacade.getResetPasswordTokenStatus(email, token)
      .pipe(
        map(result => {
          switch (result.status) {
            case TokenStatus.None: {
              this.errorsService.showCustomErrorPage(
                "For assistance, Admins should contact their Idea Financial Business Advisor and team members should contact their company admin.",
                "Unable to reset password",
                ErrorActionType.BackToLogin
              )
              return false;
            }
            case TokenStatus.Invalid: {
              this.errorsService.showCustomErrorPage(
                `Your password reset link has expired. Please request a new one.`,
                "This link has expired",
                ErrorActionType.BackToLogin,
                ErrorDescriptionType.HTML
              )
              return false;
            }
            default: {
              return true;
            }
          }
        })
      )
  }
}
